export const SO_T_Material = [
    {
        img:'facebook-icon.svg',
        heading:'Facebook'
    },
    {
        img:'instagram-icon.svg',
        heading:'Instagram'
    },
    {
        img:'linkedin-icon.svg',
        heading:'Linkedin'
    },
    {
        img:'twitter-icon.svg',
        heading:'Twitter'
    },
    {
        img:'pinterest-icon.svg',
        heading:'Pinterest'
    },
    {
        img:'snapchat-icon.svg',
        heading:'SnapChat'
    },
    {
        img:'youtube-icon.svg',
        heading:'Youtube'
    },
    {
        img:'tiktok-icon.svg',
        heading:'Tiktok'
    },
]