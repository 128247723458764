import React from 'react';
import './App.css';
import Navbar from './Nav/Navbar';
import { Routes, Route } from 'react-router-dom'
import Service from './Service/Service';
import Quality from './Quality/Quality';
import Contact from './Contact/Contact';
import Company from './Company/Company';
import Footer from './Footer.jsx/Footer';
import Home from './Home/Home';
import WebDevelopment from './Service/Web Development/WebDevelopment';
import MobileAppDevelopment from './Service/Mobile App Development/MobileAppDevelopment';
import Softwaretesting from './Service/Software testing/Softwaretesting'
import DataAnalysis from './Service/Data Analysis/DataAnalysis'
import SEO from './Service/SEO/SEO';
import UIDesign from './Service/UI Design/UIDesign';
import SocialMedia from './Service/Social Media/SocialMedia';
import LTesting from './Quality/LTesting';

function App() {
  return (
    <div>
       <Navbar />
  <Routes>
  <Route path='/' element={<Home/>}/>
   {/*------------------------- Service DropDown ------------------------------*/}
   <Route path='/Web' element={<WebDevelopment/>}/>
   <Route path='/MobileApp' element={<MobileAppDevelopment/>}/>
   <Route path='/Softwaretesting' element={<Softwaretesting/>}/>
   <Route path='/DataAnalysis' element={<DataAnalysis/>}/>
   <Route path='/SEO' element={<SEO/>}/>
   <Route path='/UIDesign' element={<UIDesign/>}/>
   <Route path='/Social' element={<SocialMedia/>}/>
   {/*------------------------- Service DropEnd --------------------------------*/}
   <Route  path='/Service' element={<Service/>}/>
   <Route path='/Quality' element={<Quality/>}/>
   {/*------------------------- Quality DropDown  Parts ------------------------------*/}
   <Route path='/Contact' element={<Contact/>}/>
   <Route path='/Company' element={<Company/>}/>
   <Route path='/LTesting' element={<LTesting/>}/>
  </Routes>
  <Footer />
    </div>
  )
}

export default App;
