import React from 'react'
import './SEO.css'
import { Para , Heading , Button, ContentContainer , TitleContainer , Span ,Center, BlackBox, BlackBoxHeading, ToolCMain, ToolContainer} from '../../Common'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import ServiceDiv from '../ServiceDiv';
import INDUSTERIES from '../INDUSTERIES';
import INcontent from '../INcontent';
import CContent from '../CContent';
import Choose from '../Choose';
import { ServiceMaterial } from '../../Quality/ServiceMaterial';
import ServiceF from '../../Quality/ServiceF';
import { S_S_Material } from './S_S_Material';
import { TestingItemSection } from '../../Common';
import { TestingItemContainer } from '../../Common';
import TestingItem from '../../Quality/TestingItem';
import { S_Exp_Material } from './S_Exp_Material';
import Typewriter from 'typewriter-effect'

const SEO = () => {
  return (
    <section className='section-SEO'>
    <div className='SEO-container'>
    <h1 className='service-heading'>  
    <Typewriter
    options={{
      autoStart:true,
      loop:true,
      delay:100,
      strings: [" GET RANKED <br/> TO GROW <br/> YOUR <br/> BUSINES."]
    }}
    />
      </h1>
      <p className='section-para'>Affordable SEO Services Provider Company.</p>
<button className='service-btn' style={{outline:'none'}}><b>LET'S TALK DETAILS</b></button>
    </div>
    <TitleContainer>
				<ContentContainer>
					<Heading>AFFORDABLE SEO SERVICES</Heading>
					<Para>You can start or advance your mobile project by utilising our custom mobile application development services. Our mobile application developers handle the full process of developing a mobile application, including business analysis, UX/UI designs of android development  and IOS development from invention to launch. We also integrate the new product into your infrastructure and offer additional optimization and scale-up as needed. Using mobile app quality technologies and  consideration quality assurance for mobile applications, we create best mobile apps for many platforms. We provide the Android development and IOS development.</Para>
					<Para>KualitySoft has been a trusted partner for clients both nationwide and International since its incorporation. Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s taking your worries off your shoulders, and who won’t rest till they’re solved.</Para>
					<Para>Doing Business with us is Easy. We measure up to your expectations. We design, develop and deploy end-to-end solutions for startups to enterprises Our Services Are Scalable, So We Can Address The Needs Of Businesses Of All Sizes.</Para>
					<Para>Companies Ranging From Innovative Startups To Well Established Companies Make Use Of Our Technical Expertise.</Para>
          <Para>Every Company says that they Satisfy their Clients.<Span>We actually do!</Span></Para>
					<Center>
						<Button>EXPLORE SERVICES</Button>
					</Center>
				
				</ContentContainer>
			</TitleContainer>

      {/*------------------------------------------- OUR SEO STRATEGY -----------------------------------------*/}

    <Heading>OUR SEO STRATEGY</Heading>
      <section className='Service-section'>
    <div  className='div-container'>
  {
    S_S_Material.map((value)=>{
     return(
    <ServiceDiv 
    img={value.img}
    heading={value.heading}/>
    ) 
  })
  }
   </div>
    </section>

      {/*------------------------------------------- GET MORE ORGANIC TRAFFIC -----------------------------*/}

<TitleContainer>
<ContentContainer>
<Row>
<Col><Heading>GET MORE <br/>ORGANIC TRAFFIC</Heading></Col>
<Col><Heading>GET MORE <br/>CONVERSIONS</Heading></Col>
</Row>
<Row>
<Col>
<Para>In this digital world, Mobile apps are inevitable for your business. It doesn't matter how large or small the scale of your business, It works almost in the same way for every scale of business. Mobile application helps you to increase your brand awareness, allow Offline access to your business, reach out to more customers, get feedback, build customer loyalty, enhance your digital marketing strategy and generate more income.</Para>
</Col>
<Col>
<Para>Have an idea for your app! that's great. But just having a great idea is not enough for a successful app. Statistics show that 71% of mobile app users abandoned in just 90 days. That means you have a short time to impress your users. That's not an easy task to perform but don't worry. Kualitysoft has absolute expertise in mobile app development and making it successful for our clients.</Para>
</Col>
</Row>
</ContentContainer>
</TitleContainer>
{/*--------------------------- OUR EXPERTISE ------------------------*/}
<Heading>OUR EXPERTISE</Heading>
<TestingItemSection>
				<TestingItemContainer>
					{
						S_Exp_Material.map((value) => {
							return (
								<TestingItem
									img={value.img}
									heading={value.heading}
									para={value.para}
								/>
							)
						})
					}
				</TestingItemContainer>
			</TestingItemSection>
{/*--------------------------- Black Box of WE DONT JUST DEVELOP WE DIGITISE YOUR NEEDS ACCURATELY----------------------------*/}
<BlackBox>
  <BlackBoxHeading>AFFORDABLE SEO SERVICES PROVIDER<br/> COMPANY</BlackBoxHeading>
</BlackBox>
{/*-------------------------- Col & Rows--------------------*/}
<TitleContainer>
<ContentContainer>
<Row >
<Col><Heading>AFFORDABLE SEO <br/> SERVICES FOR ECOMMERCE WEBSITES</Heading></Col>
<Col><Heading>AFFORDABLE SEO <br/>SERVICES FOR  SMALL BUSINESS</Heading></Col>
</Row>
<Row>
<Col>
<Para>We are always glad to serve an E-commerce Website as an SEO Services Provider Company. We move you in the right marketing direction and show your content to the particular traffic to make sales. To get maximum outcomes, there are many strategies and action plans in our ideas bucket to make your website profitable for your business.</Para>
<Para>
All our Search Engine Optimization Experts are familiar with using and doing e-commerce SEO on platforms like WordPress, Shopify, Wix etc. We are fully capable to do Product Page Optimization, Technical Optimization, implementation of keywords and all that thing that helps your web pages to get ranked.</Para>
</Col>
<Col>
<Para>The purpose of this section is to clear that it doesn’t matter how big are your business. We have a complete package of SEO to offer small business owners to increase their visibility on major search engines. Although our consultancy is available for you to guide you that which service your business actually wants.</Para>
<Para>As result, you would get more organic traffic and conversions towards the goal. You would be built your service/product awareness. You would rank higher on SERPs which is the goodness of SEO.</Para>
</Col>
</Row>
</ContentContainer>
</TitleContainer>
{/*-------------------------- Black Box  of MAXIMISE YOUR TEAMS PRODUCTIVITY--------------------*/}
<BlackBox>
  <BlackBoxHeading>
  CHOOSE AFFORDABLE SEO SERVICES<br/> PROVIDER <br/>
CHOOSE KUALITYSOFT
</BlackBoxHeading>
</BlackBox>
{/*------------------------ INDUSTRY Section ---------------------------*/}
<ContentContainer>
    <TitleContainer fluid="md">
    <Row>
      <Col><Heading>INDUSTRIES WE SERVE</Heading></Col>
    </Row>
    <Row>
      <Col><Para>Being located in Europe and Asia. KualitySoft retains the experience of working with a wide range of industries across the globe over a long time. We do not just develop but provide digital solutions to clients across all industries. We analyze key requirements of the industry and goals of the organization. We serve and put our best practices and team to address them and come up with a vital solution for our clients.</Para></Col>
    </Row>
    </TitleContainer>
  </ContentContainer>
    <section className='Industry-section'>
    <div  className='Industry-container'>
 {
  INcontent.map((value)=>{
    return(
      <INDUSTERIES
      img={value.img}
      heading={value.heading}
      />
    )
  })
 }
 </div>
 </section>
 <ContentContainer>
    <TitleContainer fluid="md">
  <Row>
      <Col><Heading>WHY CHOOSE US?</Heading></Col>
  </Row>
  </TitleContainer>
  </ContentContainer>
 <section className='Choose-section'>
    <div  className='Choose-container'>
 {
  CContent.map((value)=>{
    return(
    <Choose img={value.img}
            heading={value.heading}
    />
    )
  })
 }
 </div>
 </section>
  {/*------------------------------ SERVICES WE OFFER -------------------------------*/}

  <Heading>SERVICES WE OFFER</Heading>

<ToolCMain>
<ToolContainer>
  {
  ServiceMaterial.map((value)=>{
   return(
     <ServiceF
     img={value.img}
     heading={value.heading}
     />
   )
  })
  }
  </ToolContainer>
  </ToolCMain>
  {/*------------  LET'S TALK --------------------------*/}
  <TitleContainer>
  <ContentContainer>
  <Heading>LET'S TALK</Heading>
  <Center>
 <Para>If you have any questions to enquire about or would like to get a quote or anything else, or want to work together Get in touch now.</Para>
 </Center>
  <Center>
  <Button>LET'S WORK TOGETHER</Button>
  </Center>
  </ContentContainer>
  </TitleContainer>
    </section>
  )
}

export default SEO