export const SO_S_Material = [
    {
        img:'specific-audience-targeting-icon.svg',
        heading:'Specific Audience Targeting'
    },
    {
        img:'better-online-exposure-icon.svg',
        heading:'Better Online Exposure'
    },
    {
        img:'enhanced-customer-trust-icon.svg',
        heading:'Enhanced Customer Trust'
    },
]