export const EMaterial = [
    {   id:'1',
        img:'functional-testing-icon.svg',
        heading:'Functional Testing'
    },
    {
        id:'2',
        img:'usability-testing-icon.svg',
        heading:'Usability Testing'
    },
    {
        id:'3',
        img:'automation-testing-icon.svg',
        heading:'Automation Testing'
    },
    {   
        id:'4',
        img:'strategy-execution-icon.svg',
        heading:'Manual Testing'
    },
    {
        id:'5',
        img:'performance-testing-icon.svg',
        heading:'Performance Testing'
    },
    {
        id:'6',
        img:'compatibility-testing-icon.svg',
        heading:'Compatibility Testing'
    },
    {
        id:'7',
        img:'api-testing-icon.svg',
        heading:'API Testing'
    },
    {
        id:'8',
        img:'web-development-services-icon.svg',
        heading:'Web Testing'
    },
    {   
        id:'9',
        img:'mobile-app-services-icon.svg',
        heading:'Mobile Testing'
    }
]