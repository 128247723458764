export const Material = [
    {
        img:'analyze-icon.svg',
        heading:'Analyze',
        para:'Analyzing to make sure the requirements are clear, consistent, complete, traceable, and testable to prevent possible defects.'
    },
    {
        img:'plan-icon.svg',
        heading:'Plan',
        para:'Based on Requirements Analysis, we develop a strategy, schedule, budget, resources and procedure for testing.'
    },
    {
        img:'design-icon.svg',
        heading:'Design',
        para:'Our QA team starts to design test cases and checklists keeping in view clear scope of work and requirements.'
    },
    {
        img:'strategy-execution-icon.svg',
        heading:'Execution',
        para:'Once the team is done with all the previous phases, our QA team adds information on every bug to the defect tracking system.'
    },
    {
        img:'reporting-icon.svg',
        heading:'Reporting',
        para:'Regular reports on each development iteration allow you to be in control of the product quality and schedule.'
    },
    {
        img:'support-release-icon-01.svg',
        heading:'Release & Support',
        para:'Last but not least, we provide full support for customers satisfaction and to bring product features to a high level.'
    }
]
