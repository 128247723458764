
import React, { Fragment } from 'react'
import { BlackBorderBottom, Heading, RButton } from '../Common'
import './Service.css'

const Delivery = () => {
  return (
    <Fragment>
<div  className='d-item-b'>
<h4 className='Heading' >We Explore</h4>
<BlackBorderBottom className='BlackBorder'/>
<p className='d-para'>This is at the heart of everything we do. We have a passion for technology, data and communication, and few things in life make us happier than that “aha!” moment, where we find a piece of the puzzle, a new solution unique to our client’s needs.</p>
</div>
<div  className='d-item-c'>
<h4 className='Heading' >We Adapt</h4>
<BlackBorderBottom className='BlackBorder'/>
<p className='d-para'>Bruce Lee urged his students to be like water, which shifted into any shape it was poured into without losing it’s essence. We believe elite developers should follow that philosophy, and adjust to any new challenge they find.</p>
</div>
<div  className='d-item-d'>
<h4 className='Heading' >We Deliver</h4>
<BlackBorderBottom className='BlackBorder'/>
<p className='d-para'>Every services company builds products and solutions. That’s important. But it’s equally important to us to build relationships and reputation.</p>
</div>
<div  className='d-item-a'>
<Heading className="Heading">WANT TO STAND OUT?</Heading>

<RButton style={{marginTop:'25%'}}>HIRE US</RButton>
</div>
        </Fragment>
  )
}

export default Delivery