export const W_E_Material = [
    {
        img:'Secured-icon.svg',
        heading:'Secured'
    },
    {
        img:'mobile-friendly-icon.svg',
        heading:'Mobile Friendly'
    }, 
    {
        img:'well-designed-icon.svg',
        heading:'Well Designed'
    },
    {
        img:'e-commerce-icon.svg',
        heading:'E-Commerce'
    },
    {
        img:'cms-based-icon.svg',
        heading:'CMS Based'
    },
    {
        img:'Scalable-icon.svg',
        heading:'Scalable'
    }
]