import React, { Fragment } from 'react'
import TestingItem from './TestingItem';
import { Material } from './Material';
import './Quality.css';
import {TestingItemContainer, TestingItemSection, Heading} from '../Common';


const LTesting = () => {
  return (
    <Fragment>	
	<Heading >OUR TESTING PROCESS</Heading>
			<TestingItemSection>
				<TestingItemContainer>
					{
						Material.map((value) => {
							return (
								<TestingItem
								{...value}
								/>
							)
						})
					}
				</TestingItemContainer>
			</TestingItemSection>
    </Fragment>
  )
}

export default LTesting