import styled from "styled-components";


export const H4 = styled.h1`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
color: #000000;
font-size: 45px;
font-weight: 900;
line-height: 1;
letter-spacing: -2px;
font-family: poppins,;
margin: 10px auto 15px;
@media screen and (max-width: 393px)
{
  font-size: 35px;
  text-align: left;
  font-weight: bolder;
}

`;
export const Span = styled.span`
color: #ef323a;
font-weight:700;
`;
export const Para = styled.p`
color: #000000;
font-size: 16px;
font-weight: 450;
line-height: 1.4;
padding-left: 5px;
padding-right: 5px;
text-align: justify;
@media screen and (max-width: 768px)
{
  font-size: 18px;
}


`;
export const Button = styled.button`
margin: 40px auto 40px;
display: inline-block;
background: #000000;
color: #fff;
border-radius: 30px;
font-weight: 700;
padding: 10px 20px;
font-size: 24px;
font-family: poppins, sans-serif;
border:none;
&:hover
{
	background: #ef323a;
   transition: all .2s;
}
&:active
{
  transform: scale(0.95);
}
@media screen and (max-width:393px)
{
  font-size: 14px;
  padding: 10px 23px 10px 23px;
}
`;

export const SButton = styled.button`
margin-top: 20%;
margin-left: 7%;
background: #ef323a;
width:9%;
height:6%;
text-align: center;
color:white;
font-size: 70%;
border-radius:30px;
position:absolute;
&:hover
{
	background: #ef323a;
   transition: all .2s;
}
&:active
{
  transform: scale(0.95);
}
@media screen and (max-width:393px)
{
  font-size: 14px;
  padding: 10px 23px 10px 23px;
}
@media screen and (min-width: 768px)
{
  margin-top: 90%;
  margin-left: 7%;
  background: #ef323a;
  width: 25%;
  height: 6%;
  text-align: center;
  color: white;
  font-size: 70%;
  border-radius: 30px;
  position: absolute;
}
@media only screen and (min-width: 992px)
{
  margin-top: 90%;
  margin-left: 7%;
  background: #ef323a;
  width: 25%;
  height: 6%;
  text-align: center;
  color: white;
  font-size: 70%;
  border-radius: 30px;
  position: absolute;
}
@media only screen and (min-width: 1200px)
{
  margin-top: 20%;
margin-left: 7%;
background: #ef323a;
width:9%;
height:6%;
text-align: center;
color:white;
font-size: 70%;
border-radius:30px;
position:absolute;
&:hover
{
	background: #ef323a;
   transition: all .2s;
}
&:active
{
  transform: scale(0.95);
}
}
`;
export const SHeading = styled.h1`
margin-top: 10%;
margin-left: 7%;
font-size: 200%;
font-weight: 700;
 font-family: poppins, sans-serif;
 color: white;
 position: absolute;
 @media only screen and (max-width: 600px)
 {
  display:none;
 }
 @media only screen and (min-width: 768px)
 {
  margin-top: 76%;
  margin-left: 7%;
  font-size: 200%;
  font-weight: 700;
  font-family: poppins,sans-serif;
  color: white;
  position: absolute;
 }
 @media only screen and (min-width: 992px)
 {
  margin-top: 76%;
  margin-left: 7%;
  font-size: 200%;
  font-weight: 700;
  font-family: poppins,sans-serif;
  color: white;
  position: absolute;
 }
 @media only screen and (min-width: 1200px)
 {
  margin-top: 10%;
margin-left: 7%;
font-size: 200%;
font-weight: 700;
 font-family: poppins, sans-serif;
 color: white;
 position: absolute;
 }
`;
export const RButton = styled.button`
height: 50px;
line-height: 50px;
background: #EF323A;
color: #fff;
border-radius: 40px;
font-weight: 700;
padding: 0 20px;
font-size: 24px;
font-family: poppins, sans-serif;
border:none;
@media screen and (max-width:393px)
{
  padding: 0px 20px 0px 20px;
  font-size: 20px;
}
`;
export const Heading = styled.h1`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
color: #000000;
font-size: 45px;
font-weight: 900;
line-height: 1;
letter-spacing: -2px;
font-family: poppins,;
margin: 10px auto 15px;
text-align:center;
margin: 100px 0  40px 0;
@media only screen and (max-width: 600px)
{
  font-size: 16px;
  padding:2%;
  letter-spacing: 0px;
}
@media only screen and (min-width: 600px) {
  font-size: 16px;
  padding:2%;
}
@media only screen and (min-width: 768px) {
  font-size: 27px;
  padding:2%;
} 
@media only screen and (min-width: 1200px) {
  color: #000000;
  font-size: 45px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -2px;
  font-family: poppins,;
  margin: 10px auto 15px;
  text-align:center;
  margin: 100px 0  40px 0;
}
`;
export const TitleContainer = styled.div`
 width:1400px;
 margin-left:auto;
 margin-right:auto;
 @media only screen and (max-width:600px)
 {
  width:100%;
  padding:0 5%;
 }
 @media only screen and (min-width:600px)
 {
  width:100%;
  padding:0 5%;
 }
 @media only screen and (min-width: 768px)
 {
  width: 100%;
  padding:0 5%;
 }
`;
export const ContentContainer = styled.div`
 width:100%;
 
`;
export const Center = styled.div`
 width:100%;
 display:inline-flex;
 justify-content:center;
 align-item:center;
 @media screen and (max-width: 393px)
 {
  width: 393px;
 }
`;
export const Item = styled.div`
text-align: center;
width: 100px;
height: 350px;
box-shadow: 0 0 2px rgb(145, 143, 143);
padding: 1rem;
padding:0 2rem;
border: 4px solid transparent;
&:hover
{
  border: 4px solid black;
  transition:0.2s;
}
@media only screen and (max-width: 600px)
{
  display:none;
}
@media only screen and (min-width: 768px) {
  width: 100%;
} 
@media only screen and (min-width: 992px) {
  width: 100%;
}
@media only screen and (min-width: 1200px) {
  .example {background: pink;}
} 
`;

export const EItem = styled.div`
text-align: center;
width: 100%;
height: 250px;
box-shadow: 0 0 2px rgb(145, 143, 143);
border: 4px solid transparent;
&:hover{
  border: 4px solid black;
  transition:0.2s;
}
@media screen and (max-width:393px)
{
  width: 185px;
}
`;

export const Img = styled.img`
width: 80px;
height: 80px;
margin-top: 3rem;
margin-bottom: 0.5rem;
`;
export const TImg = styled.img`
width: 80px;
height: 80px;
margin-top: 3rem;
`;
export const H3 = styled.h3`
line-height: 30px;
font-size: 18px;
font-weight: 700;
/* text-transform: uppercase; */
letter-spacing: -1px;
font-family: poppins, sans-serif;
margin: 20px auto 15px;
color: #000000;
letter-spacing: 0;
text-decoration: none;
transition: all .3s;
`;
export const TestingItemSection = styled.div`
width: 1400px;
margin: 0 auto;
@media screen and (max-width: 600px)
{
  width: 100%;
  padding:2%;
}
@media only screen and (min-width: 768px) {
  width: 100%;
  padding:2%;
} 
@media only screen and (min-width: 992px) {
  width: 100%;
  padding:2%;
} 
@media only screen and (min-width: 1200px) {
  width: 100%;
  padding:0 5%;
}
`;
export const TestingItemContainer = styled.div`
display:grid;
grid-template-columns: repeat(auto-fit,minmax(466px,auto));
@media only screen and (max-width: 600px)
{
  display:grid;
  grid-template-columns: auto auto;
}
@media only screen and (min-width: 600px) {
  display:grid;
  grid-template-columns: auto auto;
}
@media only screen and (min-width: 768px) {
  display:grid;
  grid-template-columns: auto auto;
} 
@media only screen and (min-width: 992px){
  display:grid;
  grid-template-columns: auto auto;
}
@media only screen and (min-width: 1200px)
{
  display:grid;
grid-template-columns: auto auto auto;
}
`;

export const  BlackBox = styled.div`
width:100%;
height:400px; 
margin-top:50px;
background-color:black; 
display:inline-flex; 
justify-content:center; 
align-items:center
`;
export const BlackBoxHeading = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
    font-size: 45px;
    text-transform: uppercase;
    color: #fff;
    font-family: poppins,;
    text-align: center;
    font-weight: 900;
    line-height: 65px;
    letter-spacing: 0px;
    @media screen and (max-width: 393px)
    {
      font-size: 39px;
    }
`;
export const   ToolItem  = styled.div`
width: 350px;
height: 280px;
box-shadow: 0 0 2px rgb(145, 143, 143);
padding:2rem 3rem;
text-align:center;
border: 4px solid transparent;
&:hover{
  border: 4px solid black;
  transition:0.2s;
}
@media only screen and (max-width: 600px)
{
  width: 100%;
}
@media only screen and (min-width: 768px) {
  width: 100%;
} 
@media only screen and (min-width: 992px) {
  width: 100%;
} 
@media only screen and (min-width: 1200px) {
  width: 350px;
  height: 280px;
  box-shadow: 0 0 2px rgb(145, 143, 143);
  padding:2rem 3rem;
  text-align:center;
}
`
export const   SOFItem  = styled.div`
text-align:center;
@media screen and (max-width: 393px)
{
  width: 185px;
}
`
export const ToolCMain = styled.div`
 width:1400px;
 margin-left:auto;
 margin-right:auto;
 @media only screen and (max-width:600px)
 {
  width: 100%;
  padding: 2%;
 }
 @media only screen and (min-width: 768px) {
  width: 100%;
  padding: 2%;
} 
@media only screen and (min-width: 992px) {
  width: 100%;
  padding:0 2%;
} 
@media only screen and (min-width: 1200px) {
  width:1400px;
 margin-left:auto;
 margin-right:auto;
}
`;
export const ToolContainer = styled.div`
display:grid;
grid-template-columns: repeat(auto-fit,minmax(350px,auto));
@media only screen and (max-width:600px)
{
display:grid;
grid-template-columns: auto auto;
}
@media only screen and (min-width: 768px) {
  display:grid;
  grid-template-columns: auto auto ; 
} 
@media only screen and (min-width: 992px) {
  display:grid;
  grid-template-columns: auto auto ; 
} 
@media only screen and (min-width: 1200px) {
  display:grid;
grid-template-columns:auto auto auto auto;
}
`;
export const DropMenu = styled.div`
width:250px;
height:350px;
margin-top:22px;
background-color:white;
position:absolute;
text-align:left;
display:none;
`;
export const DropMenuS = styled.div`
width:250px;
height:300px;
margin-top:22px;
background-color:white;
position:absolute;
text-align:left;
display:none;
`;
export const DropMenuT = styled.div`
width:250px;
height:150px;
margin-top:22px;
background-color:white;
position:absolute;
text-align:left;
display:none;
`;
export const BorderBottom = styled.div`
@media only screen and (min-width: 600px) {
  border-bottom: 2px solid red;
  width: 100px;
  margin-bottom: 10px;
  margin-top: -40px; 
}
@media only screen and (min-width: 992px) {
  border-bottom: 2px solid red;
  width: 100px;
  margin-bottom: 10px;
  margin-top: -60px; 
}
@media only screen and (min-width: 1200px) {
  border-bottom: 2px solid red;
  width: 150px;
  margin-bottom: 10px;
  margin-top: -60px; 
} 
`;
export const BlackBorderBottom = styled.div`
@media only screen and (max-width: 600px) {
  display:inline-flex;
  align-item:left;
  border-bottom: 2px solid black;
  width: 100px;
  margin-bottom: 10px;
}
@media only screen and (min-width : 768px)
{
  display:inline-flex;
  align-item:left;
  border-bottom: 2px solid black;
  width: 100px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) {
  display:inline-flex;
  align-item:left;
  border-bottom: 2px solid black;
  width: 100px;
  margin-bottom: 10px;
 
}
@media only screen and (min-width: 1200px) {
  display:inline-flex;
  align-item:left;
  border-bottom: 2px solid black;
  width: 100px;
  margin: 5% 0 10% 0;
}

`;

export const  HBlackBorderBottom = styled.div`
@media only screen and (max-width: 600px) {
  display:inline-flex;
  align-item:left;
  border-bottom: 2px solid red;
  width: 200px;
}
@media only screen and (min-width : 768px)
{
  display:inline-flex;
  align-item:left;
  border-bottom: 2px solid red;
  width: 200px;

}
@media only screen and (min-width: 992px) {
  display:inline-flex;
  align-item:left;
  border-bottom: 2px solid red;
  width: 200px;

 
}
@media only screen and (min-width: 1200px) {
  display:inline-flex;
  align-item:left;
  border-bottom: 2px solid red;
  width: 200px;
  margin:4.5% 0 0 0;
} 

`;

export const BContainer = styled.div`
width: 100%;
padding: 0 5%;
@media only screen and (max-width: 600px) {
  width: 100%;
}
@media only screen and (min-width: 600px) {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  width: 100%;
} 

@media only screen and (min-width: 992px) {
  width: 100%;
} 

@media only screen and (min-width: 1200px) {
  width: 100%;
padding: 0 5%;
}
`;

export const BSContainer = styled.div`
display: grid;
grid-template-columns: auto auto auto;
margin-top: 3rem;
@media only screen and (max-width: 600px) {
  display: grid;
  grid-template-columns: auto auto;
}
@media only screen and (min-width: 600px) {
  display: grid;
grid-template-columns: auto;
}
@media only screen and (min-width: 768px) {
  display: grid;
  grid-template-columns: auto auto auto;
} 

@media only screen and (min-width: 992px) {
  display: grid;
grid-template-columns: auto auto auto;
} 
@media only screen and (min-width: 1200px) {
display: grid;
grid-template-columns: auto auto auto;
margin-top: 3rem;
}
`;
export const CSitem= styled.div`
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 250px;
    box-shadow: 0 0 1px rgb(180, 180, 180);
    cursor: pointer;
    &:hover
    {
      background-color:black;
    }
`;