import React from 'react'

const Choose = (props) => {
  return (
    <div  className='C-item'>
    <img src={props.img} alt='' className='img'/>
    <h4>{props.heading}</h4>
   </div>
  )
}

export default Choose