import React from 'react'
import { CSitem, Img } from '../Common';
import { Link } from 'react-router-dom';

function Citem(props) {
  return (
      <CSitem className='CSitem'>
      <Link to={`${props.Link}`}><Img className='CSimg' src={props.img} alt=''/></Link>
     <h4 className='Home-D-Heading'>{props.heading}</h4>
      </CSitem>
  )
}

export default Citem
