import React, { Fragment, useState } from 'react';
import * as AiIcons from 'react-icons/ai';
import {Link} from 'react-router-dom';
import './Navbar.css';
import  {FaFacebookF,FaTwitter,FaInstagram,FaLinkedinIn} from 'react-icons/fa'
import { SiGooglemybusiness} from 'react-icons/si'
import {RiMenu3Fill} from 'react-icons/ri'
import {  SButton, SHeading } from '../Common';


function OffcanvasExample() {
  const [sidebar , setsidebar] = useState(false)
  const [color, setColor] = useState(false)

  const showSidebar = () => {
    setTimeout(()=>{
    setsidebar(!sidebar);
  },100)
  }
  
  const Handle = () =>
  {
    if(window.scrollY >= 80){
      setColor('black')
    }
    else{
      setColor('white')
    }  
  }
  window.addEventListener('scroll',Handle);

  return (
    <Fragment>
     <div className="navbar">
      
      <Link to='#' className='menu-bars'>
      <RiMenu3Fill onClick={showSidebar} className={sidebar ? 'nav-menu' : 'active'}  style={{color:`${color}`}}/>
      </Link>

      <nav className={sidebar ? 'active' : 'nav-menu'}>
      
        <ul className="nav-menu-items">
          <li className="nav-toggle">
            <AiIcons.AiOutlineClose onClick={showSidebar} />
          </li>
          <li>
          <SHeading>
          LET'S BREAK <br/> THE ICE
          </SHeading>
          <div className='Button-side'>
          <SButton>HIRE US</SButton>
          </div>
          </li>
          <ol className='nav-link-items'>
          <li className='Side_link'><Link to='/' className='Side_bar_link'  onClick={showSidebar}>HOME</Link></li>
          <li className='Side_link'><Link to='/Service' className='Side_bar_link'  onClick={showSidebar}>SERVICES</Link></li>
          <li className='Side_link'><Link to='/Quality' className='Side_bar_link'  onClick={showSidebar}>QUALITY ASSURANCE</Link></li>
          <li className='Side_link'><Link to='/Company' className='Side_bar_link'  onClick={showSidebar}>COMPANY</Link></li>
          </ol>
          <div className='icons-container'>
        <div className='menu-icons'>
        <a href='https://www.linkedin.com/company/kualitysoft/" class="lnkd-icon" target="_blank'><FaLinkedinIn className='LinkedinIn f-icons' /></a>
        <a href='https://www.facebook.com/kualitysoft/'><FaFacebookF/></a>
        <a href='https://twitter.com/kualitysoft/'><FaTwitter /></a>
        <a href='https://www.instagram.com/kualitysoft/'> <FaInstagram /></a>
        <a href='https://www.google.com/maps/place/KualitySoft./@32.2042473,74.1963084,17z/data=!3m1!4b1!4m5!3m4!1s0x391f291dfa84199b:0x23befcf10f8763ed!8m2!3d32.2042473!4d74.1963084'><SiGooglemybusiness/></a>
         </div>
         </div>
        </ul>
      </nav>
     </div>
    </Fragment>
  );
}

export default OffcanvasExample;