export const TMaterial = [
    {
        img:'tfs-icon.avif',
        heading:'TFS'
    },
    {
        img:'jira-icon.avif',
        heading:'Jira'
    },
    {
        img:'bugzilla-icon.avif',
        heading:'Bugzilla'
    },
    {
        img:'selenium-icon.avif',
        heading:'Selenium'
    },
    {
        img:'postman-icon.avif',
        heading:'Postman'
    },
    {
        img:'TestNG-icon.avif',
        heading:'TestNG'
    },
    {
        img:'loadRunner-icon.avif',
        heading:'LoadRunner'
    },
    {
        img:'jmeter-icon.avif',
        heading:'JMeter'
    }
]