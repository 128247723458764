export const ServiceMaterial = [
    {
        img:'web-testing-01.svg',
        heading:'Web Development'
    },
    {
        img:'mobile-app-services-icon.svg',
        heading:'Mobile Development'
    },
    {
        img:'software-testing-services-icon-01.svg',
        heading:'Software Testing'
    },
    {
        img:'data-analytics-services-icon.svg',
        heading:'Data Analytics'
    },
    {
        img:'analytics-reporting-services-icon.svg',
        heading:'Analytic Reporting'
    },
    {
        img:'seo-services-icon.svg',
        heading:'SEO'
    },
    {
        img:'ui-design-services-icon-01.svg',
        heading:'UI Design'
    },
    {
        img:'social-media-services-icon.svg',
        heading:'Social Media'
    }
]

