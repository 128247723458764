import React from 'react'
import './Company.css'
import '../Service/Service.css'
import Choose from '../Service/Choose'
import CContent from '../Service/CContent'
import { Heading,Button,Para,Span,H4, BlackBoxHeading, BlackBox, HBlackBorderBottom} from '../Common'
import HomeContent from '../Home/HomeContent'
import Development from '../Home/Development'
import Delivery from '../Service/Delivery'
import Typewriter from 'typewriter-effect'

const Company = () => {
  return (
    <section className='company-section'>
    <div className='Company-container'>
    <h1 className='service-heading'>

  <Typewriter
  options={{
    autoStart:true,
    loop:true,
    delay:100,
    strings: ["WE ARE <br/> KUALITYSOFT."]
  }}
  />
  </h1>
  <HBlackBorderBottom/>
  <p className='Home-para'>Your Business Deserve KualitySoftwares.</p>
  <button className='Home-btn' style={{ outline: 'none'}}><b>LET'S TALK DETAILS</b></button>
        </div>
        {/*------------------------- Home-Welcome ----------------------------*/}
    <div className='Home-Welcome'>

      <div className='H-W-Container'>
        <div className='H-C-Content'>
        <H4>WELCOME TO <br/><Span> KUALITYSOFT.</Span></H4>
<Para>
KualitySoft has been a trusted partner for clients both nationwide and internationally since incorporation. 
Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep 
knowing that there’s someone out there who’s taking your worries off your shoulders, and who won’t rest till 
they’re solved.
</Para>
<br/>
<Para>
Doing Business with us is Easy. We measure up to your expectations. We design, develop and deploy
end-to-end solutions for startups to enterprises Our Services Are Scalable, So We Can Address The Needs Of Businesses
Of All Sizes.
</Para>
<Para>
Companies Ranging From Innovative Startups To Well Established Companies Make Use Of Our Technical Expertise.
Every Company says that they Satisfy their Clients.
<br/><br/><Span>We actually do!</Span>
</Para>
<Button>EXPLORE SERVICES</Button>
        </div>

        <div className='H-D-Container'>
        <div className='H-D-Div'>
        {
         HomeContent.map((value)=>{
          return(
          <Development
          img={value.img}
          heading={value.heading}
          />
          )
        })
        }
        </div>
      </div>
      </div>
    </div>
    <Heading>HOW WE DO</Heading>
    <section className='Service-section'>
    <div  className='div-container'>
 
    <Delivery/>
   
   </div>
    </section>
    <BlackBox>
      <BlackBoxHeading>EXPECT NOTHING,<br/>
BUT QUALITY.</BlackBoxHeading>
    </BlackBox>
{/*------------------------- WHY CHOOSE US -------------------------*/}
        <Heading>WHY CHOOSE US?</Heading>
            <section className='Choose-section'>
          <div  className='Choose-container'>
            {
               CContent.map((value)=>{
                return(<Choose
                  img={value.img}
                  heading={value.heading}
                />) 
              })
            }
             </div>
       </section>
       </section>
  )
}

export default Company