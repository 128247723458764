export const W_Tec_Material = [
    {
        img:'html-5-icon.svg',
        heading:'HTML5'
    },
    {
        img:'css3-icon.svg',
        heading:'CSS3'
    },
    {
        img:'javascript-icon.svg',
        heading:'Javacript'
    },
    {
        img:'bootstrap-icon.svg',
        heading:'Bootstrap'
    },
    {
        img:'jquery-icon.svg',
        heading:'jQuery'
    },
    {
        img:'net-icon.svg',
        heading:'.NET'
    },
    {
        img:'php-icon.svg',
        heading:'Php'
    },
    {
        img:'angularjs-icon.svg',
        heading:'Angular'
    },
    {
        img:'laravel-icon.svg',
        heading:'Laravel'
    },
    {
        img:'wordpress-icon.svg',
        heading:'WordPress'
    },
    {
        img:'shopify-icon.svg',
        heading:'Shopify'
    },
    {
        img:'sql-server-icon.svg',
        heading:'SQL Server'
    },
]