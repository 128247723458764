export const UI_T_Material = [
    {
        img:'adobe-photoshop-icon.svg',
        heading:'Adobe Photoshop'
    },
    {
        img:'adobe-illustrator-icon.svg',
        heading:'Adobe Illustrator'
    },
    {
        img:'adobe-xd-icon.svg',
        heading:'Adobe XD'
    },
    {
        img:'html-5-icon.svg',
        heading:'HTML5'
    },
    {
        img:'css3-icon.svg',
        heading:'CSS3'
    },
    {
        img:'javascript-icon.svg',
        heading:'Javacript'
    },
]