const CitemContent = [
    {
       img:'web-testing-01.svg',
       heading:'Web Development',
       Link:'/Web',
       heading1:'WEB DEVELOPMENT SERVICES',
       para1:'KualitySoft has been a trusted partner for clients both nationwide and International since its incorporation. Work with us as close as you like, get updates, and take adv antage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s handling your worries off your shoulders, and who won’t rest till they’re solved.',
    },
    {
        img:'mobile-testing-01.svg',
        heading:'Mobile Development',
        Link:'/MobileApp',
        heading1:'MOBILE APP DEVELOPMENT SERVICES',
        para1:'You can start or advance your mobile project by utilising our custom mobile application development services. Our mobile application developers handle the full process of developing a mobile application, including business analysis, UX/UI designs of android development and IOS development from invention to launch. We also integrate the new product into your infrastructure and offer additional optimization and scale-up as needed. Using mobile app quality technologies and consideration quality assurance for mobile applications, we create best mobile apps for many platforms. We provide the Android development and IOS development.',

    },
    {
        img:'software-testing-services-icon-01.svg',
        heading:'Software Testing',
        Link:'/Softwaretesting',
        heading1:'SOFTWARE TESTING SERVICES',
        para1:'As an Independent Software Testing & QA Services Partner Company providing QA testing services for any software product. If your software works on the Web, Mobile, or a handheld device, We can test it.',
    },
    {
        img:'data-analytics-services-icon.svg',
        heading:'Data Analytics',
        Link:'/DataAnalysis',
        heading1:'DATA ANALYTICS SERVICES',
        para1:'KualitySoft has been a trusted partner for clients both nationwide and International since its incorporation. Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s taking your worries off your shoulders, and who won’t rest till they’re solved.',
    },
    {
        img:'seo-services-icon.svg',
        heading:'SEO',
        Link:'/UIDesign',
        heading1:'AFFORDABLE SEO SERVICES',
        para1:'As an Independent SEO Services Provider Company, we’re housed by Senior SEO Experts. KualitySoft always respects your expected outcomes and do efforts all the time to meet them. We ensure your privacy and policy to get things done. We keep reminding your Reputation to make the Best SEO Strategy. We have the pretty experience of deals with brands and enterprises across the world. Get the authenticity, dependency, validity and reliability under one window, give a try to us.<br/><br/><br/>There’s no shortcut and fixed time to get ranked higher. We always used to implement legal techniques and activities that are accordingly more reliable and more reputed way. KualitySoft avoids taking shortcuts. Anything against the law will be penalized one day, it would be on earth or search engines. Shortcuts always give you short success, and we want long-term solutions.',
    },
    {
        img:'social-media-services-icon.svg',
        heading:'Social Media',
        Link:'/Social',
        heading1:'SOCIAL MEDIA SERVICES COMPANY',
        para1:'KualitySoft has been a trusted partner for clients both nationwide and International since its incorporation. Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s taking your worries off your shoulders, and who won’t rest till they’re solved.',
    },
   
]
export default CitemContent 