import React from 'react'
import './Contact.css'
import '../Service/Service.css'
import Choose from '../Service/Choose'
import CContent from '../Service/CContent'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import  {FaFacebookF,FaTwitter,FaInstagram,FaLinkedinIn} from 'react-icons/fa';
import {SiGooglemybusiness} from 'react-icons/si'
import { AddressMap } from './AddressMap'
import { BlackBox, BlackBoxHeading, Center, HBlackBorderBottom, Heading } from '../Common'
import Typewriter from 'typewriter-effect'

const Contact = () => {
  return (
    <section className='section-contact'>
    <div className='Contact-container'>
    <h1 className='service-heading'>
   
    <Typewriter
    options={{
      autoStart:true,
      loop:true,
      delay:100,
      strings: [" LET'S BREAK <br/> THE ICE."]
    }}
    />
  </h1>
  <HBlackBorderBottom/>
  <p className='Home-para'>Your Business Deserve KualitySoftwares.</p>
  <button className='Home-btn' style={{ outline: 'none'}}><b>LET'S TALK DETAILS</b></button>
      </div>
      {/*------------------------ Geo Map Location ---------------------------*/}
      <Heading>GET IN TOUCH WITH US</Heading>
      <Center>
      <AddressMap/>
      </Center>
      {/*------------------------ ContactUs --------------------------------*/}
      <Container fluid="md">
  <Row>
      <Col className='text-center'><h1 style={{fontWeight:'1000', fontSize:'35px'}}>WHY CHOOSE US?</h1></Col>
  </Row>
 </Container>
      <section className='Choose-section'>
    <div  className='Choose-container'>
      {
         CContent.map((value)=>{
          return(<Choose
            img={value.img}
            heading={value.heading}
          />) 
        })
      }
       </div>
 </section>

      <BlackBox>
 <BlackBoxHeading>WE ARE HERE FOR YOU.</BlackBoxHeading>
      <p style={{color:'white', position:'absolute', marginBottom:'-6rem', fontSize:'20px'}}>Connect with us today.</p>
      <div style={{color:'white', position:'absolute', marginBottom:'-10rem', fontSize:'25px'}}>
      <a href='https://www.linkedin.com/company/kualitysoft/'><FaLinkedinIn style={{marginRight:'2rem', color:'white'}}/></a>
      <a href='https://www.facebook.com/kualitysoft/'><FaFacebookF style={{marginRight:'2rem', color:'white'}}/></a>  
      <a href='https://twitter.com/kualitysoft/'> <FaTwitter style={{marginRight:'2rem', color:'white'}}/> </a>
      <a href='https://www.instagram.com/kualitysoft/'> <FaInstagram style={{marginRight:'2rem', color:'white'}}/> </a>
        <a href='https://www.google.com/maps/place/KualitySoft./@32.2042473,74.1963084,17z/data=!3m1!4b1!4m5!3m4!1s0x391f291dfa84199b:0x23befcf10f8763ed!8m2!3d32.2042473!4d74.1963084'><SiGooglemybusiness style={{color:'white'}}/></a>
      </div>
      </BlackBox>
      </section>    
  )
}

export default Contact