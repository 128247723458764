import React from 'react'
import { SOFItem, TImg , H3 } from '../Common'

const ServiceF = (props) => {
  return (
    <SOFItem>
    <TImg src={props.img} alt=''/>
    <H3>{props.heading}</H3>
  </SOFItem>
  )
}

export default ServiceF