import React from 'react';
import './Quality.css';
import { Para, Heading, TitleContainer, ContentContainer, Span, Button,RButton, Center, TestingItemSection, TestingItemContainer, BlackBox, BlackBoxHeading, ToolCMain, ToolContainer, HBlackBorderBottom } from '../Common';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LTesting from './LTesting';
import { EMaterial } from './EMaterial';
import EXPERTISE from './EXPERTISE';
import { TMaterial } from './TMaterial';
import Tool from './Tool';
import INDUSTERIES from '../Service/INDUSTERIES';
import INcontent from '../Service/INcontent';
import '../Service/Service.css';
import Choose from '../Service/Choose';
import ServiceF from './ServiceF';
import CContent from '../Service/CContent';
import { ServiceMaterial } from './ServiceMaterial';
import Typewriter from 'typewriter-effect';

const Quality = () => {
	return (
		<section className='Quality-Section'>
			<div className='Quality-container'>
			<h1 className='service-heading'>WE START<br/>
			WITH 
	 <Typewriter
		options={{
		  autoStart:true,
		  loop:true,
		  delay:100,
		  strings: ["PRODUCT.","PROBLEMS.","INEFFICIENCY."]
		}}
		/>
		</h1>
		<HBlackBorderBottom/>
		<p className='Home-para'>Expert Software Testing Services Provider Company</p>
		<button className='Home-btn' style={{ outline: 'none'}}><b>LET'S TALK DETAILS</b></button>
			</div>
			<TitleContainer>
				<ContentContainer>
					<Heading>SOFTWARE TESTING SERVICES</Heading>
					<Para>To develop and manage a best applications and websites, you no longer need to spend thousands of dollars. Instead, you can make use of the affordable options offered by KualitySoft software testing. Standards for quality assurance including(Functional Testing, Software Testing)  must be in place in order to offer high-quality products consistently. KualitySoft follow strict guidelines to bring light hidden defects that could compromise user experience, ruin site functioning, and trigger many difficulties throughout the website. Additionally, Functional testing and software testing can stop a poorly functioning website, protecting your company's reputation as well as time and money.</Para>
					<Para>As an Independent Software Testing & QA Services Partner Company providing QA testing services for any software product. If your software works on the Web, Mobile, or a handheld device, We can test it.</Para>
					<Para>Doing Business with us is Easy. We measure up to your expectations. We design, develop and deploy end-to-end solutions for startups to enterprises Our Services Are Scalable, So We Can Address The Needs Of Businesses Of All Sizes. Companies Ranging From Innovative Startups To Well Established Companies Make Use Of Our Technical Expertise.</Para>
					<Para>Every Company says that they Satisfy their Clients.<Span>We actually do!</Span></Para>
				
					<Center>
						<Button>EXPLORE SERVICES</Button>
					</Center>
					<Row>
						<Col><Heading>END-TO-END <br />SOFTWARE TESTING</Heading></Col>
						<Col><Heading>CUSTOMER-FRIENDLY MOBILE APPS</Heading></Col>
					</Row>
					<Row>
						<Col><Para>We offer a vast variety of software testing services by the most popular software testing methodology to Functional Test the flow of an application. We help in application testing from beginning to end through testing tools. This methodology mainly facilitates testing bemefits simulating real user scenarios and validating systems for data integrity.</Para>
							<Para>
								Our experience and expertise in the most advanced testing tools and technologies allow us to provide top-notch software testing services and create solutions for complex problems to enhance your software quality.</Para>
						</Col>
						<Col><Para>KualitySoft has well-designed software testing processes which can test large-scale systems, applications, and other software products. So the test reports driven through are efficient and trusted for our clients.</Para>
							<Para>
								Our Dedicated QA team and efficient functional testing process give us the ability to evaluate functionality, Identify Bugs/Defects and Identify gaps between existing and expected conditions, to define the actual performance of your software.</Para>
						</Col>
					</Row>
				</ContentContainer>
			</TitleContainer>
	        {/*--------------------------- OUR TESTING PROCESS -------------------------*/}
			   <LTesting/>
			{/*-------------------------- Black Box -------------------------------*/}

			<BlackBox>
				<BlackBoxHeading> 
					QUALITY ASSURANCE ?  All iN ONE PLACE
				</BlackBoxHeading>
			</BlackBox>
			{/*--------------------------------- Expertise ------------------------*/}
			<Heading>OUR EXPERTISE</Heading>
			<TestingItemSection>
				<TestingItemContainer>
					{
						EMaterial.map((value) => {
							return (
								<EXPERTISE
									{...value}
								/>
							)
						})
					}
				</TestingItemContainer>
			</TestingItemSection>
			<BlackBox>
				<BlackBoxHeading>
					WE DONT JUST TEST<br />
					WE MAKE YOUR PRODUCTS <br />
					SUCCESSFUL.
				</BlackBoxHeading>
			</BlackBox>
			<TitleContainer>
				<ContentContainer>
					<Row>
                    <Col><Heading>TEST DESIGN & EXECUTION
ON-DEMAND</Heading></Col>
					<Col><Heading>QA AUDIT & PROCESS <br/>
IMPROVEMENT</Heading></Col>
					</Row>
					<Row>
					<Col><Para>Our On-Demand QA testing services for test design and test execution will allow you to schedule our resources whenever you need to.</Para></Col>
					<Col><Para>We can evaluate your processes of communication, testing, and accountability for efficiency and effectiveness to enhance, and optimize.</Para></Col>
					</Row>
					<Row>
                    <Col><Heading>CONSULTING</Heading></Col>
					<Col><Heading>FULL LIFE-CYCLE</Heading></Col>
					</Row>
					<Row>
					<Col><Para>With Real Mobile devices and browser/OS combinations, we can certify all your products for worldwide use</Para></Col>
					<Col><Para>We can execute test cases overnight without a need for automation scripts whenever you need us</Para></Col>
					</Row>
				</ContentContainer>
		</TitleContainer>
		

		{/*------------------------------- ToolCMain ------------------------------*/}

		<Heading>TOOLS WE USE</Heading>

		<ToolCMain>
			<ToolContainer>
		{
           TMaterial.map((value)=>{
			return(
             <Tool
			 {...value}
			 />
			)
		   })
		}
		</ToolContainer>
		</ToolCMain>

{/*-------------------------- Black Box -------------------------------*/}

					<BlackBox>
				<BlackBoxHeading> 
				QUALITY IS NOT EXPENSIVE, IT’S PRICELESS.
				</BlackBoxHeading>
			</BlackBox>

{/*-------------------------- INDUSTRIES WE SERVE -------------------------------*/}

			<Heading>INDUSTRIES WE SERVE</Heading>
<ContentContainer>
  <TitleContainer>
    <Row>
      <Col><Para>Being located in Europe and Asia. KualitySoft retains the experience of working with a wide range of industries across the globe over a long time. We do not just develop but provide digital solutions to clients across all industries. We analyze key requirements of the industry and goals of the organization. We serve and put our best practices and team to address them and come up with a vital solution for our clients.</Para></Col>
    </Row>
    </TitleContainer>
    </ContentContainer>
    <section className='Industry-section'>
    <div  className='Industry-container'>
 {
  INcontent.map((value)=>{
    return(
      <INDUSTERIES
	  {...value}
      />
    )
  })
 }
 </div>
 </section>
				{/*-------------------------- Black Box -------------------------------*/}
				<BlackBox>
				<BlackBoxHeading> 
				IN NEED OF BEST<br/>
             SOFTWARE TESTING COMPANY
			 <Center>
			 <RButton>GIVE KUALITY A TRY</RButton>
			 </Center>
				</BlackBoxHeading>
			</BlackBox>
			{/*--------------------------------- Why choose us -------------------------------*/}
			<Heading>WHY CHOOSE US?</Heading>
			<section className='Choose-section'>
    <div  className='Choose-container'>
	{
  CContent.map((value)=>{
    return(
    <Choose 	{...value}
    />
    )
  })
 }
 </div>
 </section>
          {/*------------------------------ SERVICES WE OFFER -------------------------------*/}

 <Heading>SERVICES WE OFFER</Heading>

 <ToolCMain>
<ToolContainer>
   {
	 ServiceMaterial.map((value)=>{
		return(
			<ServiceF
			{...value}
			/>
		)
	 })
   }
   </ToolContainer>
   </ToolCMain>
   {/*---------------L--------------------- ET'S TALK --------------------------*/}
   <TitleContainer>
   <ContentContainer>
   <Heading>LET'S TALK</Heading>
   <Center>
	<Para>If you have any questions to enquire about or would like to get a quote or anything else, or want to work together Get in touch now.</Para>
	</Center>
   <Center>
   <Button>LET'S WORK TOGETHER</Button>
   </Center>
   </ContentContainer>
   </TitleContainer>
		</section>
	)
}

export default Quality