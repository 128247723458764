import React from 'react'
import {  ToolItem , H3, TImg } from '../Common'

const Tool = (props) => {
    return(
  <ToolItem>
    <TImg src={props.img} alt=''/>
    <H3>{props.heading}</H3>
  </ToolItem>
  )
}

export default Tool