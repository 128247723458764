import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import FooterContent from './FooterContent'
import FooterContentS from './FooterContentS'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa'
import { SiGooglemybusiness } from 'react-icons/si'

const Footer = () => {
const  gototop = () => window.scrollTo({top:3200, behavior:'smooth',})
const  gotop = () => window.scrollTo({top:0, behavior:'smooth',})
  return (
    <div className='Footer-Container'>
      <div className='Footer-item'>
        {
          FooterContent.map((value) => {
            return (
              <ul className='Footer-list'>
                <h5 className='F_Heading'><b>{value.heading}</b></h5>
                <li><Link to={`${value.l}`} onClick={gototop} className='Link'>{value.p1}</Link></li>
                <li><Link to={`${value.l2}`}  className='Link'>{value.p2}</Link></li>
                <li><Link to={`${value.l3}`}  className='Link'>{value.p3}</Link></li>
                <li><Link to={`${value.l4}`}  className='Link'>{value.p4}</Link></li>
                <li><Link to={`${value.l5}`}  className='Link'>{value.p5}</Link></li>
                <li><Link to={`${value.l6}`}  className='Link'>{value.p6}</Link></li>
                <li><Link to={`${value.l7}`}  className='Link'>{value.p7}</Link></li>
              </ul>
            )
          })
        }

        {
          FooterContentS.map((value) => {
            return (
              <ul className='Footer-listS'>
                <li><Link to='#' className='Link l-a'>{value.p1}</Link></li>
                <li><Link to='#' className='Link l-b'>{value.p2}</Link></li><br />
                <li><Link to='#' className='Link l-c'>{value.p3}</Link></li>
                <li><Link to='#' className='Link l-d'>{value.heading}</Link></li>
                <li><div className='Icons'>
                <a href='https://www.linkedin.com/company/kualitysoft/" class="lnkd-icon" target="_blank'><FaLinkedinIn className='LinkedinIn f-icons' /></a>
                <a href='https://www.facebook.com/kualitysoft/'><FaFacebookF className='Facebook f-icons' /></a>
                <a href='https://twitter.com/kualitysoft/'><FaTwitter className='Twitter f-icons' /></a>
                <a href='https://www.instagram.com/kualitysoft/'> <FaInstagram className='Instagram f-icons' /></a>
                <a href='https://www.google.com/maps/place/KualitySoft./@32.2042473,74.1963084,17z/data=!3m1!4b1!4m5!3m4!1s0x391f291dfa84199b:0x23befcf10f8763ed!8m2!3d32.2042473!4d74.1963084'><SiGooglemybusiness className='GooglePlay f-icons' /></a>
              </div></li><br />
                <li><Link to='#' className='Link l-e'>{value.p4}</Link></li>
                <li><Link to='#' className='Link l-f'>{value.p5}</Link></li>
                <li><Link to='#' className='Link l-g'>{value.p6}</Link></li>
                <li><Link to='#' className='Link l-h'>{value.p7}</Link></li>
                <li><img className='pseb' src='1.svg' alt=''/><img className='psebt' src='2.svg'/></li>
              </ul>
            )
          })
        }
      </div>
      <div className='Footer-bottom' >
      <Link to='/' className='I-img' onClick={gotop}> <img src='made-with-love-by-kualitySoft.avif' alt=''  /></Link> 
        <div className='Icons'>
        <a href='https://www.linkedin.com/company/kualitysoft/" class="lnkd-icon" target="_blank'><FaLinkedinIn className='LinkedinIn f-icons' /></a>
        <a href='https://www.facebook.com/kualitysoft/'><FaFacebookF className='Facebook f-icons' /></a>
        <a href='https://twitter.com/kualitysoft/'><FaTwitter className='Twitter f-icons' /></a>
        <a href='https://www.instagram.com/kualitysoft/'> <FaInstagram className='Instagram f-icons' /></a>
        <a href='https://www.google.com/maps/place/KualitySoft./@32.2042473,74.1963084,17z/data=!3m1!4b1!4m5!3m4!1s0x391f291dfa84199b:0x23befcf10f8763ed!8m2!3d32.2042473!4d74.1963084'><SiGooglemybusiness className='GooglePlay f-icons' /></a>
      </div>
      </div>
    </div>
  )
}

export default Footer