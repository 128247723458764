const CContent = [
    {
        img:'Transparency-01.svg',
        heading:'100% Transparency'
      },
      {
          img:'on-time.svg',
          heading:'On Budget & Time'
      },
      {
          img:'MVP Development-01-01.svg',
          heading:'MVP Development'
      },
      {
          img:'Flexible Engagement-01.svg',
          heading:'Flexible Engagement'
      },
      {
        img:'support-release-icon-01.svg',
        heading:'Unmatched Support'
    }
]

export default CContent