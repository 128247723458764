import React from 'react'
import './Service.css'


const ServiceDiv = (props) => {
  return (
 
    <div  className='div-item'>
     <img src={props.img} alt='' className='img'/>
     <h4>{props.heading}</h4>
    </div>
   
  )
}

export default ServiceDiv