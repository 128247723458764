export const UI_D_Material = [
    {
        img:'discovery-icon.svg',
        heading:'Discovery'
    },
    {
        img:'sketching-and-wireframe-icon.svg',
        heading:'Sketching & Wireframes'
    },
    {
        img:'build.svg',
        heading:'Build & Iterate'
    },
    {
        img:'usability-testing-icon%20(1).svg',
        heading:'Usability Testing'
    },
]