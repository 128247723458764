import React from 'react'
import { Center, H3, Img, Item  } from '../Common'

const TestingItem = (props) => {
  return (
    <Item>
    <Img src={props.img} alt=''/>
    <H3>{props.heading}</H3>
    <Center>{props.para}</Center>
    </Item>
  )
}

export default TestingItem