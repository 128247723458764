import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import ToogleT from './ToogleT';
import { DropMenu,DropMenuS,DropMenuT } from '../Common';

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const [color, setColor] = useState(false);
  const [buttonColor,setbuttonColor] = useState(false);
  const [Hire , setHire] = useState(false);

  /*---------------------------------------- Img nav kuality --------------------------------------*/
  
  const [Kimg , setKimg] = useState('none');
  const [KWimg , setKWimg] = useState(false);
  const changeNavbarColor = () =>{
     if(window.scrollY >= 80){
       setColorchange(true);
       setColor('black');
       setbuttonColor('red');
       setHire('white');
       setKimg('block');
       setKWimg('none');
     }
     else{
       setColorchange(false);
       setColor('');
       setbuttonColor('');
       setHire('black');
       setKimg('none');
       setKWimg('block');
     }
  };
  useEffect(()=>{
  window.addEventListener('scroll', changeNavbarColor,true);
  return () => window.removeEventListener('scroll',changeNavbarColor);
},[]);

 const gotoBottom = () => 
 {
  window.scrollTo({
    top:3200,behavior:'smooth',
   })
 }
 const goBottom = () => 
 {
  window.scrollTo({
    top:800,behavior:'smooth',
   })
 }
 const topscroll = () =>
  {
    window.scrollTo({top:0,behavior:'smooth',})
  }
  return (
    <Fragment>
    <section id='nav-container' className={colorChange ? 'navbar colorChange' : 'navbar'}>
    <Link to='./' style={{display:`${Kimg}`} }><img src='KualitySoft.png' alt='' className='nav-img' onClick={topscroll}/></Link>
    <Link to='./' style={{display:`${KWimg}`}}><img src='logo-01.svg' alt='' className='nav-img' onClick={topscroll}/></Link>
        {/*------------------------------ nav-links ------------------------------- */}
        <div id='nav-link'>
            <ul>
              {/*----------------------------------- Drop Down Service  Start------------------------------*/}
           <li className='drop-service'> <Link to='/Service' className='link link_l' style={{color:`${color}`}} onClick={topscroll}>
              Service
              <DropMenu className='sub-drop'>
              <li><Link to='/Web' className='linkO'>Web Development</Link></li>
              <li><Link to='/MobileApp' className='linkO'>Mobile App Development</Link></li>
              <li><Link to='/Softwaretesting' className='linkO'>Software Testing</Link></li>
              <li><Link to='/DataAnalysis' className='linkO'>Data Analytics</Link></li>
              <li><Link to='/SEO' className='linkO'>SEO</Link></li>
              <li><Link to='/UIDesign' className='linkO'>UI Design</Link></li>
              <li><Link to='/Social' className='linkO'>Social Media</Link></li>
              </DropMenu>
           </Link></li>
              {/*----------------------------------- Drop Down Service  End------------------------------*/}

              {/*----------------------------------- Drop Down Quality  Start------------------------------*/}

                <li className='drop-Quality'> <Link to='/Quality' className='link link_l' style={{color:`${color}`}}> Quality Assurance
                <DropMenuS className='sub-dropS'> 
              <li><Link to='/Quality' onClick={gotoBottom} className='linkS'>Functional Testing</Link></li> 
              <li><Link to='#'  onClick={gotoBottom} className='linkS'>Web App Testing</Link></li> 
              <li><Link to='#'  onClick={gotoBottom} className='linkS'>Mobile App Testing</Link></li> 
              <li><Link to='#'  onClick={gotoBottom} className='linkS'>SQA Consulting</Link></li> 
              <li><Link to='#'  onClick={gotoBottom} className='linkS'>Full Life Cycle Testing</Link></li> 
              <li><Link to='#'  onClick={gotoBottom} className='linkS'>Dedicated QA Teams</Link></li> 
                </DropMenuS>
                </Link></li>

              {/*----------------------------------- Drop Down Quality  End------------------------------*/}

              {/*----------------------------------- Drop Down Company  Start------------------------------*/} 

                <li className='drop-Company'><Link to='/Company' className='link link_l' style={{color:`${color}`}}>Company
              <DropMenuT className='sub-dropT'>
              <li><Link to='#' onClick={goBottom} className='linkT'>About</Link></li> 
              <li><Link to='#' className='linkT'>What We Do</Link></li> 
              <li><Link to='#' className='linkT'>How We Do</Link></li> 
              </DropMenuT>
                </Link></li>

             {/*----------------------------------- Drop Down Company  End------------------------------*/} 

                <li><Link to='/Contact' className='link link_l' style={{color:`${color}`}} onClick={topscroll}>Contact</Link></li>

              {/*----------------------------------- Drop Down Quality  End------------------------------*/}  

                <li><Link to='/Contact' className='link link_l' style={{color:`${color}`}}>|</Link></li>
                <li><a href='https://api.whatsapp.com/send/?phone=923156461550&text&app_absent=0' className='link link_l' style={{color:`${color}`}}>+92.315.646.1550</a></li>
                <li><Link to='/Contact' className='link link-btn' style={{backgroundColor:`${buttonColor}`,color:`${Hire}`}}>HIRE US</Link></li>
                </ul>
                <ToogleT/>
            </div>
    </section>
    </Fragment>
  )
}

export default Navbar