export const D_Exp_Material = [
    {
        img:'financial-analytics.svg',
        heading:'Financial Analytics'
    },
    {
        img:'customer-analytics.svg',
        heading:'Customer Analytics'
    },
    {
        img:'manufacturing-analytics.svg',
        heading:'Brand Analytics'
    },
    {
        img:'product-analytics.svg',
        heading:'Product Analytics'
    },
    {
        img:'asset-analytics.svg',
        heading:'Asset Analytics'
    },
    {
        img:'brand-analytics.svg',
        heading:'Manufacturing Analytics'
    },
]