import React from 'react'
import './Home.css'
import Development from './Development'
import HomeContent from './HomeContent'
import Delivery from '../Service/Delivery'
import '../Service/Service.css'
import {H4,Span,Para,Button,Heading,TitleContainer,ContentContainer,BlackBox,BlackBoxHeading, BorderBottom, HBlackBorderBottom} from '../Common'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import INDUSTERIES from '../Service/INDUSTERIES'
import INcontent from '../Service/INcontent'
import CContent from '../Service/CContent'
import Choose from '../Service/Choose'
import Typewriter from 'typewriter-effect'

const Home = () => {
  return (
    <section className='section-Home'>
    <div className='Home-container'>
    <h1 className='service-heading'>
<Typewriter
options={{
  autoStart:true,
  loop:true,
  delay:100,
  strings: ["COMBINING<br/>BIG IDEAS <br/> WITH <Span> PERFECT</Span> <br> EXECUTION."]
}}
/>
<div/>
</h1>
<HBlackBorderBottom/>
<p className='Home-para'>Your Business Deserve KualitySoftwares.</p>
<button className='Home-btn' style={{ outline: 'none'}}><b>LET'S TALK DETAILS</b></button>
    </div>

    {/*--------------------- Welcome ----------------------------*/}

    <div className='Home-Welcome'>
      <div className='H-W-Container'>
        
        <div className='H-C-Content Top-banner'>
        <H4>WELCOME TO <br/><Span> KUALITYSOFT.</Span></H4>
<Para>
KualitySoft has been a trusted partner for clients both nationwide and internationally since incorporation. 
Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep 
knowing that there’s someone out there who’s taking your worries off your shoulders, and who won’t rest till 
they’re solved.
</Para>
<br/>
<Para>
Doing Business with us is Easy. We measure up to your expectations. We design, develop and deploy
end-to-end solutions for startups to enterprises Our Services Are Scalable, So We Can Address The Needs Of Businesses
Of All Sizes.
</Para>
<Para>
Companies Ranging From Innovative Startups To Well Established Companies Make Use Of Our Technical Expertise.
Every Company says that they Satisfy their Clients.
<br/><br/><Span>We actually do!</Span>
</Para>
<Button>EXPLORE SERVICES</Button>
        </div>

        <div className='H-D-Container'>
        <div className='H-D-Div'>
        {
         HomeContent.map((value)=>{
          return(
          <Development
          {...value}
          />
          )
        })
        }
        </div>
      </div>
      </div>
    </div>

    {/*--------------------------- HOW WE DO --------------------------*/}
    
    <Heading>HOW WE DO</Heading>
    <BorderBottom/>
    <section className='Service-section'>
    <div  className='div-container'>

    <Delivery/>

   </div>
    </section>

    {/*----------------- Black Box -------------------------*/}

    <BlackBox>
    <BlackBoxHeading> EXPECT NOTHING,<br/>
BUT QUALITY.
</BlackBoxHeading>
    </BlackBox>
    
    <Heading>INDUSTRIES WE SERVE</Heading>
    <BorderBottom/>
<ContentContainer>
  <TitleContainer>
    <Row>
      <Col><Para>Being located in Europe and Asia. KualitySoft retains the experience of working with a wide range of industries across the globe over a long time. We do not just develop but provide digital solutions to clients across all industries. We analyze key requirements of the industry and goals of the organization. We serve and put our best practices and team to address them and come up with a vital solution for our clients.</Para></Col>
    </Row>
    </TitleContainer>
    </ContentContainer>
    <section className='Industry-section'>
    <div  className='Industry-container'>
 {
  INcontent.map((value)=>{
    return(
      <INDUSTERIES
      {...value}
      />
    )
  })
 }
 </div>
 </section>

 {/*---------------------------- WHY CHOOSE US? -------------------------*/}

 <Heading>WHY CHOOSE US?</Heading>
 <BorderBottom/>
 <section className='Choose-section'>
    <div  className='Choose-container'>
 {
  CContent.map((value)=>{
    return(
    <Choose     {...value}
    />
    )
  })
 }
 </div>
 </section>
    </section>
  )
}

export default Home