import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Service.css'
import ImagesContent from './ImagesContent';
import ServiceDiv from './ServiceDiv';
import Delivery from './Delivery';
import INcontent from './INcontent';
import INDUSTERIES from './INDUSTERIES';
import Choose from './Choose';
import CContent from './CContent';
import { Para,Heading,TitleContainer,ContentContainer,BlackBox,BlackBoxHeading, BorderBottom, BContainer, BSContainer } from '../Common'
import CitemContent from './CitemContent'
import Citem from './Citem'

const SContent = () => {
  return (
    <>
    <BContainer>
    <BSContainer>
    {
   CitemContent.map((value)=>{
      return(
      <Citem
      {...value}
      />
      )
    })
    }
    </BSContainer>
    </BContainer>
   <ContentContainer className='ContentContainer'>
    <TitleContainer fluid="md" className='TitleContainer'>
   {/*  <Row>
      <Col><Heading>WEB DEVELOPMENT SERVICES</Heading></Col>
    </Row>
    <Row>
    <Col>
     <Para>
      KualitySoft has been a trusted partner for clients both nationwide and
      International since its incorporation. Work with us as close as you like, get updates, and take 
      adv antage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s 
         handling your worries off your shoulders, and who won’t rest till they’re solved.</Para>
    </Col>
    </Row>
    <Row>
      <Col><Heading>USER-CENTERED WEB DEVELOPMENT SERVICES</Heading></Col>
      <Col><Heading>CUSTOM WEB <br/>DEVELOPMENT SERVICES</Heading></Col>
    </Row>
    <Row>
        <Col >
        <Para>
         A web app developed on well-designed UI/UX leads to successful apps.Increase 
         conversion rates to countable numbers. Our Development Team is summed up  of 
         creative UI/UX designers. Our Experienced developers are skilled with the latest 
         technologies to design user-centred systems that help your business to grow and reach out to more customers. 
         That's what all you need from a Website.</Para></Col>
        <Col><Para>Our skilled and experienced web development team is on its feet to turn your ideas into a real-world products. 
        The Product is exactly as you imagined.
         We are suited up with the latest technologies. 
         And Open Source Technologies to make your business secure and give it a boost in the market. 
         We develop all sorts of Web apps & Websites for every domain.
          From a simple CMS, custom developed to an e-commerce store.</Para></Col>
    </Row> */}
    <Row >
        <Col><Heading>OUR STRATEGY</Heading>
        <BorderBottom/>
        </Col>
    </Row>
    
  </TitleContainer>
  </ContentContainer>
  <section className='Service-section'>
    <div  className='div-container'>
  {
    ImagesContent.map((value)=>{
     return(
    <ServiceDiv 
    img={value.img}
    heading={value.heading}/>
    ) 
  })
  }
   </div>
    </section>
    <ContentContainer>
    <TitleContainer fluid="md">

    {/*<Row >
      <Col><Heading>SOFTWARE TESTING SERVICES</Heading>
      <BorderBottom/>
      </Col>
    </Row>
    <Row className='Row'>
      <Col><Para>As an Independent Software Testing &
         QA Services Partner Company providing QA testing services for any software product. 
        If your software works on the Web, Mobile, or a handheld device, We can test it.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>WHY ANALYTICS YOU NEED?</Heading></Col>
      <Col><Heading>WHAT KIND OF DATA YOU NEED?</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col ><Para>
      Data analytics helps you to optimize your business performance. For better performance of your business, you need to analyze every aspect, to make better business decisions. Data analytics helps you analyze new trends and factors of customer satisfaction to improve your product or services accordingly.
      </Para></Col>
      <Col ><Para>Choosing the right data insights is a difficult task and requires a lot of expertise. KualitySoft's skilled and experienced data analytics team guides you to the exact services according to your business niche, your needs, and your requirements. So you get a brief insight into your business data, to make better decisions for the future.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>END-TO-END SOFTWARE TESTING</Heading></Col>
      <Col><Heading>CUSTOMER-FRIENDLY MOBILE APPS</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col ><Para>We offer a vast variety of software testing services by the most popular software testing methodology to test the flow of an application. We help in application testing from beginning to end. This methodology mainly facilitates simulating real user scenarios and validating systems for data integrity.
Our experience and expertise in the most advanced tools and technologies allow us to provide top-notch software testing services and create solutions for complex problems to enhance your software quality.</Para></Col>
      <Col ><Para>KualitySoft has well-designed software testing processes which can test large-scale systems, applications, and other software products. So the test reports driven through are efficient and trusted for our clients.

Our Dedicated QA team and efficient testing process give us the ability to evaluate functionality, Identify Bugs/Defects and Identify gaps between existing and expected conditions, to define the actual performance of your software.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>DATA ANALYTICS SERVICES</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col ><Para>
KualitySoft has been a trusted partner for clients both nationwide and International since its incorporation. Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s taking your worries off your shoulders, and who won’t rest till they’re solved.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>WHY ANALYTICS YOU NEED?</Heading></Col>
      <Col><Heading>WHAT KIND OF DATA YOU NEED?</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col ><Para>Data analytics helps you to optimize your business performance. For better performance of your business, you need to analyze every aspect, to make better business decisions. Data analytics helps you analyze new trends and factors of customer satisfaction to improve your product or services accordingly.</Para></Col>
      <Col ><Para>Choosing the right data insights is a difficult task and requires a lot of expertise. KualitySoft's skilled and experienced data analytics team guides you to the exact services according to your business niche, your needs, and your requirements. So you get a brief insight into your business data, to make better decisions for the future.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>AFFORDABLE SEO SERVICES</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col ><Para>As an Independent SEO Services Provider Company, we’re housed by Senior SEO Experts. KualitySoft always respects your expected outcomes and do efforts all the time to meet them. We ensure your privacy and policy to get things done. We keep reminding your Reputation to make the Best SEO Strategy. We have the pretty experience of deals with brands and enterprises across the world. Get the authenticity, dependency, validity and reliability under one window, give a try to us.</Para>
      <br/><Para>
There’s no shortcut and fixed time to get ranked higher. We always used to implement legal techniques and activities that are accordingly more reliable and more reputed way. KualitySoft avoids taking shortcuts. Anything against the law will be penalized one day, it would be on earth or search engines. Shortcuts always give you short success, and we want long-term solutions.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>GET MORE ORGANIC TRAFFIC</Heading></Col>
      <Col><Heading>GET MORE CONVERSIONS</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col><Para>We’re providing Professional and Affordable solutions. The KualitySoft team is passionate to increase your organic traffic. Traffic that is ideal for any business. We always stay updated with google-core updates and the latest techniques. Giving results that meet your expectations is our initial priority and we never forget our priorities.</Para></Col>
      <Col><Para>Our uncommon strategies, attention to detail activities, and rare techniques of our team makes us unique. We're committed to Rank for conversions. We claim it as our strength. Productive Ideas makes always the actions more efficient. So, being an SEO services provider company we want to be efficient to get maximum outcomes.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>UI DESIGN SERVICES COMPANY</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col><Para>Your User Interface is the first visual impression of your site or app. Efficient designs attract & retain users. An Elegant interface has a lower probability of traffic bounce rate while having a higher chance for conversions. It's a human psyche that if you develop something that fascinates or clicks someone's mind. They will continue to want to engage with it. As a UI Design Services Company, we can say that your UI/UX plays a vital role in conversions & building trust.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>IMPRESIVE DESIGN THAT ATTRACTS</Heading></Col>
      <Col><Heading>DESIGNS THAT DELIVER RESULTS</Heading></Col>
    </Row>
      <Row className='Row'>
      <Col><Para>We always try to implement all best practices over designing an interference. We make sure that the system communicates what's happening. We make the UI pretty simple, consistent, planned & more strategic. Create a well-organized hierarchy and clarity for the User. Simply Get a Layout that Impresses everyone.</Para></Col>
      <Col><Para>We are Brilliant enough to design the UI that delivers results. We try to minimize the user's efforts whenever it is possible. Save the time of your audience. We have our own & separate strategies. We are focused on creating visually appealing interfaces. Get more conversion by our UI Service in less time.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>SOCIAL MEDIA SERVICES COMPANY</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col><Para>KualitySoft has been a trusted partner for clients both nationwide and International since its incorporation. Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s taking your worries off your shoulders, and who won’t rest till they’re solved.</Para></Col>
    </Row>
    <Row className='Row'>
      <Col><Heading>EXPAND YOUR BUSINESS</Heading></Col>
      <Col><Heading>REACH NEW CUSTOMERS</Heading></Col>
    </Row>
      <Row className='Row'>
      <Col><Para>We’re providing Professional and Affordable solutions. KualitySoft team is passionate to Expand your Business. Traffic that is ideal for any business, for any social media presence. We always stay updated with the latest techniques. Giving results that go beyond your expectations is our initial priority and we never forget our priorities.</Para></Col>
      <Col><Para>Our uncommon strategies, attention to detail activities, and rare techniques of our team make us unique. We claim it as our strength. Productive Ideas makes always the actions more efficient. So, being an Affordable Social Media services provider company we want to be efficient to give maximum outcomes to your business.</Para></Col>
</Row> */}
    <Row className='Row'>
      <Col><Heading>HOW WE DO.</Heading>
      <BorderBottom/>
      </Col>
    </Row>
    </TitleContainer>
  </ContentContainer>
    <section className='Service-section'>
    <div  className='div-container'>
 
    <Delivery/>
 
   </div>
    </section>
    <BlackBox>
    <BlackBoxHeading>EXPECT NOTHING,<br/>
BUT QUALITY.</BlackBoxHeading>
</BlackBox>
<ContentContainer>
    <TitleContainer fluid="md">
    <Row className='Row'>
      <Col><Heading>INDUSTRIES WE SERVE</Heading></Col>
    </Row>
    <Row className='Row'>
      <Col><Para>Being located in Europe and Asia. KualitySoft retains the experience of working with a wide range of industries across the globe over a long time. We do not just develop but provide digital solutions to clients across all industries. We analyze key requirements of the industry and goals of the organization. We serve and put our best practices and team to address them and come up with a vital solution for our clients.</Para></Col>
    </Row>
    </TitleContainer>
  </ContentContainer>
    <section className='Industry-section'>
    <div  className='Industry-container'>
 {
  INcontent.map((value)=>{
    return(
      <INDUSTERIES
    {...value}
      />
    )
  })
 }
 </div>
 </section>
 <ContentContainer>
    <TitleContainer fluid="md">
  <Row className='Row'>
      <Col><Heading>WHY CHOOSE US?</Heading></Col>
  </Row>
  </TitleContainer>
  </ContentContainer>
 <section className='Choose-section'>
    <div  className='Choose-container'>
 {
  CContent.map((value)=>{
    return(
    <Choose img={value.img}
            heading={value.heading}
    />
    )
  })
 }
 </div>
 </section>
  </>
  )
}

export default SContent