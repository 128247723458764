const FooterContent = [
    {
      id:1,
      heading:'COMPANY',
      l:'',
      l2:'',
      l3:'',
      l4:'',
      l5:'',
      l6:'',
      p1:'Explore  KualitySoft',
      p2:'What We Do',
      p3:'How We Do',
      p4:'',
      p5:'Contact',
      p6:'Sitemap',
      
    },
    {
        id:2,
        heading:'SERVICES',
        l:'MobileApp',
        l2:'Web',
        l3:'Softwaretesting',
        l4:'DataAnalysis',
        l5:'SEO',
        l6:'UIDesign',
        l7:'Social',
        p1:'Mobile App Development',
        p2:'Web Development',
        p3:'Software Testing',
        p4:'Data Analytics',
        p5:'SEO',
        p6:'UI Design',
        p7:'Social Media',
      },
      {
        heading:'QUALITY ASSURANCE',
        p1:'Functional Testing',
        p2:'Web App Testing',
        p3:'Mobile App Testing',
        p4:'SQA Consulting',
        p5:'Full Life Cycle Testing',
        p6:'Dedicated QA Teams',
        l:'Quality',
        l2:'Quality',
        l3:'Quality',
        l4:'Quality',
        l5:'Quality',
        l6:'Quality',
        l7:'Quality',
        method:'gototop'
      },
        
]
export default FooterContent


