export const M_E_Material = [
    {
        img:'android-apps.svg',
        heading:'Android App'
    },
    {
        img:'ios-apps.svg',
        heading:'IOS App'
    },
    {
        img:'native-apps.svg',
        heading:'Native App'
    },
    {
        img:'hybrid-app-icon.svg',
        heading:'Hybrid App'
    },
    {
        img:'progressive-web-apps-icon.svg',
        heading:'Progressive Web App'
    },
    {
        img:'wearable-apps.svg',
        heading:"Wearable's App"
    },
]