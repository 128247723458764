import React from 'react'
import './Service.css'

const INDUSTERIES = (props) => {
  return (
    <div  className='I-item'>
    <img src={props.img} alt='' className='img'/>
    <h4>{props.heading}</h4>
   </div>
  )
}

export default INDUSTERIES