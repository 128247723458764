import React from 'react'
import { Link } from 'react-router-dom'
import './Home.css'

const Development = (props) => {
  return (
    <div className='Home-Development-item'>
    <Link to={`${props.Link}`}> <img src={props.img}  className='img' alt=''/></Link>
     <h4 className='Home-D-Heading'>{props.heading}</h4>
    </div>
  )
}

export default Development