export const UI_Exp_Material = [
    {
        img:'usability-testing-icon%20(1).svg',
        heading:'Efficient'
    },
    {
        img:'perfect-visibility-icon.svg',
        heading:'Perfect Visibility'
    },
    {
        img:'attractive-icon.svg',
        heading:'Attractive'
    },
    {
        img:'well-designed-icon.svg',
        heading:'Well Designed'
    },
    {
        img:'handsome-color-scheme.svg',
        heading:'Handsome Color Scheme'
    },
    {
        img:'better-results-icon.svg',
        heading:'Better Result'
    },
]