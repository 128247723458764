export const S_Exp_Material = [
    {
        img:'keyword-research-icon.svg',
        heading:'Keyword Research',
        para:'We provide you with the most Specific and targeted data through our Keyword Research and Analysis that are nears to your Audience Intent & hopefully will help you in conversions.'
    },
    {
        img:'on-page-seo-icon.svg',
        heading:'On-Page SEO',
        para:'We will professionally implement different practices and necessary changes on web pages that help you to get rank higher & increase the appearance on search engines organically.'
    },
    {
        img:'off-page-seo-icon.svg',
        heading:'Off-Page SEO',
        para:'We perfectly launch the series of optimizing on various actions, activities and techniques that happens away from the website that will earns you more traffic and promote your website.'
    },
    {
        img:'off-page-seo-icon.svg',
        heading:'Technical SEO',
        para:"We establish the credibility of the website and server according to search engines' guidelines. We help and control the crawling bots over-indexing and make changes in website architecture in the technical aspect."
    },
    {
        img:'local-seo-icon.svg',
        heading:'Local SEO',
        para:'We Launch the best strategy that makes your business more visible in local search results to defeat your local competitors and emerge your business as a hometown hero and more known business.'
    },
    {
        img:'voice-search-seo-icon.svg',
        heading:'Voice Search SEO',
        para:"We optimize your web pages for voice search queries to get appear on that phrase results that your audience wants to know about. You would get an extra plus point to get more traffic. If you have not heard about it before, it's time to fix it."
    },
]