export const SO_Exp_Material = [
    {
        img:'social-media-marketing-icon.svg',
        heading:'Social Media Marketing'
    },
    {
        img:'social-media-management-icon.svg',
        heading:'Social Media Management'
    },
    {
        img:'content-creation-icon.svg',
        heading:'Content Creation'
    },
    {
        img:'creative-content-campaign-icon.svg',
        heading:'Creative Content Campaign'
    },
    {
        img:'compatibility-testing-icon.svg',
        heading:'Customized Conversion Strategy'
    },
    {
        img:'detailed-targeting-icon.svg',
        heading:'Detailed Targeting'
    },
]