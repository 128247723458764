const INcontent = [
    {
      img:'Healthcare industry icon-01.svg',
      heading:'Health Care'
    },
    {
        img:'Education Industry Icon-01.svg',
        heading:'Education'
    },
    {
        img:'Hospitality Industry Icon-01.svg',
        heading:'Hospitality'
    },
    {
        img:'Finance Industry Icon-01.svg',
        heading:'Finance'
    },
    {
        img:'Transportation Industry Icon-01.svg',
        heading:'Transportation'
    },
    {
        img:'Service Industry Icon-01.svg',
        heading:'Service Industory'
    },
    {
        img:'Telecommunication Industry Icon-01.svg',
        heading:'Telecom'
    },
    {
        img:'Public Service Industry Icon-01.svg',
        heading:'Public Service'
    }

]

export default INcontent