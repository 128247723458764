import React from 'react'
import './Service.css'
import SContent from './SContent'
import Typewriter from 'typewriter-effect'
import { HBlackBorderBottom } from '../Common'

const Service = () => {
  return (
    <section className='section-servic'>
    <div className='Service-container'>
    <h1 className='service-heading'>

<Typewriter
options={{
  autoStart:true,
  loop:true,
  delay:100,
  strings: ["CREATING <br/> AN  OPTIMISED  <br/> AND ENHANCED <br/> WITH  DIGITAL"]
}}
/>
</h1>
<HBlackBorderBottom/>
<p className='section-para'>Your Business Deserve KualitySoftwares.</p>
<button className='service-btn' style={{outline:'none'}}><b>LET'S TALK DETAILS</b></button>
    </div>
    <SContent/>
    </section>
  )
}

export default Service