import React from 'react'
import { EItem , H3 , Img } from '../Common'

const EXPERTISE = (props) => {
  return (
    <EItem>
    <Img src={props.img} alt=''/>
    <H3>{props.heading}</H3>
    </EItem>
  )
}

export default EXPERTISE