import React from 'react'
import './Data.css'
import { Para , Heading , Button, ContentContainer , TitleContainer , Span ,Center, BlackBox, BlackBoxHeading, ToolCMain, ToolContainer, RButton} from '../../Common'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import INDUSTERIES from '../INDUSTERIES';
import INcontent from '../INcontent';
import CContent from '../CContent';
import Choose from '../Choose';
import { ServiceMaterial } from '../../Quality/ServiceMaterial';
import ServiceF from '../../Quality/ServiceF';
import { D_H_Material } from './D_H_Material';
import { D_Exp_Material } from './D_Exp_Material';
import { D_Tool_Material } from './D_Tool_Material';
import { TestingItemContainer } from '../../Common';
import { TestingItemSection } from '../../Common';
import EXPERTISE from '../../Quality/EXPERTISE';
import Typewriter from 'typewriter-effect'

const DataAnalysis = () => {
  return (
    <section className='section-DataAnalysis'>
    <div className='DataAnalysis'>
    <h1 className='service-heading'>  
    CONVERT YOUR <br/> RAW DATA <br/> INTO <br/>
    <Typewriter
    options={{
      autoStart:true,
      loop:true,
      delay:100,
      strings: [" VALID INSIGHTS."]
    }}
    />
      </h1>
      <p className='section-para'>Big Data Analytics Services Providers Company.</p>
<button className='service-btn' style={{outline:'none'}}><b>LET'S TALK DETAILS</b></button>
    </div>
    <TitleContainer>
				<ContentContainer>
					<Heading>DATA ANALYTICS SERVICES</Heading>
					<Para>KualitySoft has been a trusted partner for clients both nationwide and International since its incorporation. Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s taking your worries off your shoulders, and who won’t rest till they’re solved.</Para>
					<Para>Doing Business with us is Easy. We measure up to your expectations. We design, develop and deploy end-to-end solutions for startups to enterprises Our Services Are Scalable, So We Can Address The Needs Of Businesses Of All Sizes.</Para>
					<Para>Companies Ranging From Innovative Startups To Well Established Companies Make Use Of Our Technical Expertise.</Para>
          <Para>Every Company says that they Satisfy their Clients.<Span>We actually do!</Span></Para>
					<Center>
						<Button>EXPLORE SERVICES</Button>
					</Center>
				
				</ContentContainer>
			</TitleContainer>

      {/*------------------------------------------- HOW WE DO -----------------------------------------*/}

    <Heading>HOW WE DO</Heading>
    <TestingItemSection>
				<TestingItemContainer>
					{
						D_H_Material.map((value) => {
							return (
								<EXPERTISE
									{...value}
								/>
							)
						})
					}
				</TestingItemContainer>
			</TestingItemSection>

      {/*------------------------------------------- USER Control & Custom WEB -----------------------------*/}

<TitleContainer>
<ContentContainer>
<Row>
<Col><Heading>BE MORE INTERACTIVE<br/> WITH CUSTOMERS</Heading></Col>
<Col><Heading>CUSTOMER-FRIENDLY<br/>MOBILE APPS</Heading></Col>
</Row>
<Row>
<Col>
<Para>In this digital world, Mobile apps are inevitable for your business. It doesn't matter how large or small the scale of your business, It works almost in the same way for every scale of business. Mobile application helps you to increase your brand awareness, allow Offline access to your business, reach out to more customers, get feedback, build customer loyalty, enhance your digital marketing strategy and generate more income.</Para>
</Col>
<Col>
<Para>Have an idea for your app! that's great. But just having a great idea is not enough for a successful app. Statistics show that 71% of mobile app users abandoned in just 90 days. That means you have a short time to impress your users. That's not an easy task to perform but don't worry. Kualitysoft has absolute expertise in mobile app development and making it successful for our clients.</Para>
</Col>
</Row>
</ContentContainer>
</TitleContainer>
{/*--------------------------- OUR EXPERTISE ------------------------*/}
<Heading>OUR EXPERTISE</Heading>
<TestingItemSection>
				<TestingItemContainer>
					{
						D_Exp_Material.map((value) => {
							return (
								<EXPERTISE
                {...value}
								/>
							)
						})
					}
				</TestingItemContainer>
			</TestingItemSection>
{/*--------------------------- Black Box of WE DONT JUST DEVELOP WE DIGITISE YOUR NEEDS ACCURATELY----------------------------*/}
<BlackBox>
  <BlackBoxHeading>TURN DATA INTO INFORMATION<br/>
AND INFORMATION INTO INSIGHT</BlackBoxHeading>
</BlackBox>
{/*-------------------------- TECHNOLOGIES WE USE --------------------*/}
<Heading>Tool WE USE</Heading>
<TestingItemSection>
				<TestingItemContainer>
					{
						D_Tool_Material.map((value) => {
							return (
								<EXPERTISE
								{...value}
								/>
							)
						})
					}
				</TestingItemContainer>
			</TestingItemSection>
{/*-------------------------- Black Box  of MAXIMISE YOUR TEAMS PRODUCTIVITY--------------------*/}
<BlackBox>
  <BlackBoxHeading>
  DATA WILL TALK TO YOU<br/>
IF YOU ARE WILLING TO LISTEN
</BlackBoxHeading>
</BlackBox>
{/*------------------------ INDUSTRY Section ---------------------------*/}
<ContentContainer>
    <TitleContainer fluid="md">
    <Row>
      <Col><Heading>INDUSTRIES WE SERVE</Heading></Col>
    </Row>
    <Row>
      <Col><Para>Being located in Europe and Asia. KualitySoft retains the experience of working with a wide range of industries across the globe over a long time. We do not just develop but provide digital solutions to clients across all industries. We analyze key requirements of the industry and goals of the organization. We serve and put our best practices and team to address them and come up with a vital solution for our clients.</Para></Col>
    </Row>
    </TitleContainer>
  </ContentContainer>
    <section className='Industry-section'>
    <div  className='Industry-container'>
 {
  INcontent.map((value)=>{
    return(
      <INDUSTERIES
      {...value}
      />
    )
  })
 }
 </div>
 </section>
 <ContentContainer>
    <TitleContainer fluid="md">
  <Row>
      <Col><Heading>WHY CHOOSE US?</Heading></Col>
  </Row>
  </TitleContainer>
  </ContentContainer>
 <section className='Choose-section'>
    <div  className='Choose-container'>
 {
  CContent.map((value)=>{
    return(
    <Choose 	{...value}
    />
    )
  })
 }
 </div>
 </section>
 {/*------------------------------------------- Black Box WANT TO TURN YOUR DATA INTO INSIGHTS? ----*/}

 <BlackBox>
				<BlackBoxHeading> 
        WANT TO TURN YOUR DATA INTO
        <br/> INSIGHTS?
			 <Center>
			 <RButton>GIVE KUALITY A TRY</RButton>
			 </Center>
				</BlackBoxHeading>
			</BlackBox>

  {/*------------------------------ SERVICES WE OFFER -------------------------------*/}

  <Heading>SERVICES WE OFFER</Heading>

<ToolCMain>
<ToolContainer>
  {
  ServiceMaterial.map((value)=>{
   return(
     <ServiceF
     {...value}
     />
   )
  })
  }
  </ToolContainer>
  </ToolCMain>
  {/*------------  LET'S TALK --------------------------*/}
  <TitleContainer>
  <ContentContainer>
  <Heading>LET'S TALK</Heading>
  <Center>
 <Para>If you have any questions to enquire about or would like to get a quote or anything else, or want to work together Get in touch now.</Para>
 </Center>
  <Center>
  <Button>LET'S WORK TOGETHER</Button>
  </Center>
  </ContentContainer>
  </TitleContainer>
    </section>
  )
}

export default DataAnalysis