export const D_H_Material = [

{
    img:'faster-decision-making.svg',
    heading:'Faster Decision Making'
},
{
    img:'enhanced_productivity.svg',
    heading:'Enhanced Productivity'
},
{
    img:'reduce-operational-cost.svg',
    heading:'Reduced Operational Costs'
}
]