const HomeContent = [
    {
       img:'web-testing-01.svg',
       heading:'Web Development',
       Link:'Web'
    },
    {
        img:'mobile-testing-01.svg',
        heading:'Mobile Development',
        Link:'MobileApp'
    },
    {
        img:'software-testing-services-icon-01.svg',
        heading:'Software Testing',
        Link:'Softwaretesting'
    },
    {
        img:'data-analytics-services-icon.svg',
        heading:'Data Analytics',
        Link:'DataAnalysis'
    },
    {
        img:'seo-services-icon.svg',
        heading:'SEO',
        Link:'SEO'
    },
    {
        img:'social-media-services-icon.svg',
        heading:'Social Media',
        Link:'UIDesign'
    },
   
]
export default HomeContent