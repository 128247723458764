const ImagesContent = [
    {
        id:1,
        img:'plan-icon.svg',
        heading:'Plan'
    },
    {
        id:2,
        img:'design-icon.svg',
        heading:'Design'
    },
    {
        id:3,
        img:'web-development-services-icon.svg',
        heading:'Development'
    },
    {
        id:4,
        img:'test-icon.svg',
        heading:'Test'
    }
]

export default ImagesContent