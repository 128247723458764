export const S_S_Material = [
    {
        img:'identify-issues.svg',
        heading:'Identify Issues'
    },
    {
        img:'competitor-analysis-icon.svg',
        heading:'Competitor Analysis'
    },
    {
        img:'make-strategy-icon.svg',
        heading:'Make Strategy'
    },
    {
        img:'strategy-execution-icon.svg',
        heading:'Execution'
    },
]