export const D_Tool_Material = [
    {
        img:'google-analytics-icon.svg',
        heading:'Google Analytics'
    },
    {
        img:'google-adwords-icon.svg',
        heading:'Google Ads'
    },
    {
        img:'bing-icon.svg',
        heading:'Bing Analytics'
    },
    {
        img:'yahoo-icon.svg',
        heading:'Yahoo Analytics'
    },
    {
        img:'powerbi-icon.svg',
        heading:'Power BI Analytics'
    },
    {
        img:'adobe-icon.svg',
        heading:'Adobe Analytics'
    },
]