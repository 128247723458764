const FooterContentS = [
    {
        p1:'Your Business Deserve KualitySoftwares.',
        p2:'HAVE QUESTIONS?',
        p3:'Get in touch with us',
        heading:'+92.315.646.1550',
        p4:'Office No. 7,',
        p5:'Phase 3, Garden Town',
        p6:'Gujranwala, Pakistan.',
        p7:'hello@kualitysoft.co.uk'
      }
]

export default FooterContentS