import React from 'react'
import './Web.css'
import '../Service.css';
import { Para , Heading , Button, ContentContainer , TitleContainer , Span ,Center, BlackBox, BlackBoxHeading, ToolCMain, ToolContainer, BorderBottom} from '../../Common'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import ImagesContent from '../ImagesContent';
import ServiceDiv from '../ServiceDiv';
import INDUSTERIES from '../INDUSTERIES';
import INcontent from '../INcontent';
import CContent from '../CContent';
import Choose from '../Choose';
import { ServiceMaterial } from '../../Quality/ServiceMaterial';
import ServiceF from '../../Quality/ServiceF';
import { W_E_Material } from './W_E_Material';
import { TestingItemContainer } from '../../Common';
import { TestingItemSection } from '../../Common';
import EXPERTISE from '../../Quality/EXPERTISE';
import { W_Tec_Material } from './W_Tec_Material';
import Typewriter from 'typewriter-effect'

const WebDevelopment = () => {
  return (
    <section className='section-container'>
      <div className='WebDevelopment-container'>
      <h1 className='service-heading'> 
      WEBSITE <br/> THAT MEETS <br/> YOUR <br/>
      <Typewriter
    options={{
      autoStart:true,
      loop:true,
      delay:100,
      strings: ["  EXPECTATIONS."]
    }}
    />
      </h1>
      <p className='section-para'>Your Business Deserve KualitySoftwares.</p>
<button className='service-btn' style={{outline:'none'}}><b>LET'S TALK DETAILS</b></button>
			</div>
      <TitleContainer>
				<ContentContainer>
					<Heading>WEB DEVELOPMENT SERVICES</Heading>
					<Para>We are website designer to fit your needs and at a good price. You may increase brand sales and draw in more people by using our website builder services. We make responsive website design that helps you keep potential consumers and draw in new ones with the help of  best web developers. Every business today needs to be online. So get in touch with us and let us develop a web applications for your brand to help you shine. Our web developers consider the essence of what our clients are attempting to express and are site-specific. We invest time and effort into creating unique website designs that uniquely represent your brand.</Para>
					<Para>KualitySoft has been a trusted partner for clients both nationwide and International since its incorporation. Work with us as close as you like, get updates, and take advantage of our Agile, iterative process. Sleep knowing that there’s someone out there who’s handling your worries off your shoulders, and who won’t rest till they’re solved.</Para>
					<Para>Doing Business with us is Easy. We measure up to your expectations. We design, develop and deploy end-to-end solutions for startups to enterprises Our Services Are Scalable, So We Can Address The Needs Of Businesses Of All Sizes.</Para>
					<Para>Companies Ranging From Innovative Startups To Well Established Companies Make Use Of Our Technical Expertise.</Para>
          <Para>Every Company says that they Satisfy their Clients.<Span>We actually do!</Span></Para>
					<Center>
						<Button>EXPLORE SERVICES</Button>
					</Center>
				
				</ContentContainer>
			</TitleContainer>

      {/*------------------------------------------- OUR STRATEGY -----------------------------------------*/}

    <Heading>OUR STRATEGY</Heading>
    <BorderBottom/>
      <section className='Service-section'>
    <div  className='div-container'>
  {
    ImagesContent.map((value)=>{
     return(
    <ServiceDiv 
    img={value.img}
    heading={value.heading}/>
    ) 
  })
  }
   </div>
    </section>

      {/*------------------------------------------- USER Control & Custom WEB -----------------------------*/}

<TitleContainer>
<ContentContainer>
<Row>
<Col><Heading>USER-CENTERED WEB <br/> DEVELOPMENT SERVICES</Heading></Col>
<Col><Heading>CUSTOM WEB<br/>DEVELOPMENT SERVICES</Heading></Col>
</Row>
<Row>
<Col>
<Para>A web application is developed on well-designed UI/UX leads to successful apps. Increase conversion rates to countable numbers. Our web development Team is summed up of creative UI/UX designers designers. Our Experienced web developers are skilled with the latest technologies to design user-centred systems and responsive web designs that help your business to grow and reach out to more customers. That's what all you need from a Website.</Para>
</Col>
<Col>
<Para>Our skilled and experienced web development team is on its feet to turn your ideas into a real-world products. The Product is exactly as you imagined. We are suited up with the latest technologies. And open source of website builder technologies to make your business secure and give it a boost in the market. We develop all sorts of Web applications & Websites for every domain. From a simple CMS, custom developed to an e-commerce store.</Para>
</Col>
</Row>
</ContentContainer>
</TitleContainer>
{/*--------------------------- OUR EXPERTISE ------------------------*/}
<Heading>OUR EXPERTISE</Heading>
<BorderBottom/>
<TestingItemSection>
				<TestingItemContainer>
					{
						W_E_Material.map((value) => {
							return (
								<EXPERTISE
									img={value.img}
									heading={value.heading}
								/>
							)
						})
					}
				</TestingItemContainer>
			</TestingItemSection>
{/*--------------------------- Black Box of WE DONT JUST DEVELOP WE DIGITISE YOUR NEEDS ACCURATELY----------------------------*/}
<BlackBox>
  <BlackBoxHeading>WE DONT JUST DEVELOP<br/>
WE DIGITISE YOUR NEEDS<br/> ACCURATELY</BlackBoxHeading>
</BlackBox>
{/*-------------------------- TECHNOLOGIES WE USE --------------------*/}
<Heading>TECHNOLOGIES WE USE</Heading>
<BorderBottom/>
<ToolCMain>
<ToolContainer>
  {
  W_Tec_Material.map((value)=>{
   return(
     <ServiceF
     img={value.img}
     heading={value.heading}
     />
   )
  })
  }
  </ToolContainer>
  </ToolCMain>
{/*-------------------------- Black Box  of MAXIMISE YOUR TEAMS PRODUCTIVITY--------------------*/}
<BlackBox>
  <BlackBoxHeading>
    MAXIMISE YOUR TEAMS<br/> PRODUCTIVITY
</BlackBoxHeading>
</BlackBox>
{/*------------------------ INDUSTRY Section ---------------------------*/}
<ContentContainer>
    <TitleContainer fluid="md">
    <Row>
      <Col><Heading>INDUSTRIES WE SERVE</Heading>
      <BorderBottom/>
      </Col>
    </Row>
    <Row>
      <Col><Para>Being located in Europe and Asia. KualitySoft retains the experience of working with a wide range of industries across the globe over a long time. We do not just develop but provide digital solutions to clients across all industries. We analyze key requirements of the industry and goals of the organization. We serve and put our best practices and team to address them and come up with a vital solution for our clients.</Para></Col>
    </Row>
    </TitleContainer>
  </ContentContainer>
    <section className='Industry-section'>
    <div  className='Industry-container'>
 {
  INcontent.map((value)=>{
    return(
      <INDUSTERIES
      img={value.img}
      heading={value.heading}
      />
    )
  })
 }
 </div>
 </section>
 <ContentContainer>
    <TitleContainer fluid="md">
  <Row>
      <Col><Heading>WHY CHOOSE US?</Heading>
      <BorderBottom/>
      </Col>
  </Row>
  </TitleContainer>
  </ContentContainer>
 <section className='Choose-section'>
    <div  className='Choose-container'>
 {
  CContent.map((value)=>{
    return(
    <Choose img={value.img}
            heading={value.heading}
    />
    )
  })
 }
 </div>
 </section>
  {/*------------------------------ SERVICES WE OFFER -------------------------------*/}

  <Heading>SERVICES WE OFFER</Heading>
  <BorderBottom/>
<ToolCMain>
<ToolContainer>
  {
  ServiceMaterial.map((value)=>{
   return(
     <ServiceF
     img={value.img}
     heading={value.heading}
     />
   )
  })
  }
  </ToolContainer>
  </ToolCMain>
  {/*------------  LET'S TALK --------------------------*/}
  <TitleContainer>
  <ContentContainer>
  <Heading>LET'S TALK</Heading>
  <Center>
 <Para>If you have any questions to enquire about or would like to get a quote or anything else, or want to work together Get in touch now.</Para>
 </Center>
  <Center>
  <Button>LET'S WORK TOGETHER</Button>
  </Center>
  </ContentContainer>
  </TitleContainer>
    </section>
  )
}

export default WebDevelopment