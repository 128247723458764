export const M_Tec_Material = [
    {
        img:'java-icon.svg',
        heading:'Java'
    },
    {
        img:'kotlin-icon.svg',
        heading:'Kotlin'
    },
    {
        img:'javascript-icon.svg',
        heading:'Javascript'
    },
    {
        img:'typescript-icon.svg',
        heading:'Typescript'
    },
    {
        img:'native-apps.svg',
        heading:'React Native'
    },
    {
        img:'angularjs-icon.svg',
        heading:'Angular'
    },  
    {
        img:'redux-icon.svg',
        heading:'Redux'
    },
    {
        img:'swift-icon.svg',
        heading:'Swift'
    },
]